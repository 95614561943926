import {useTranslation} from 'react-i18next';
import {ContentItem} from '../contentItem';

interface IProps {
  activeItem: string;
  setActiveItem: (item: string) => void;
}

export function Android({activeItem, setActiveItem}: IProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <>
      <h3 className='uppercase'>{t('instalation-instructions')}</h3>
      <hr />
      <ContentItem
        type='default'
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        title='General'
        text={
          <>
            <p>
              1. Head to <b>Settings</b>.
            </p>
            <p>
              2. Go to <b>Apps & Notifications</b> - select configured apps.
            </p>
            <p>
              3. Scroll down. Find and tap <b>Advanced Options</b> or{' '}
              <b>Special App Access</b>.
            </p>
            <p>
              4. Scroll to the bottom menu and click on{' '}
              <b>Install Unknown Apps Options</b>.
            </p>
            <p>
              5. Find the <b>Browser app</b>, tap and allow from this source.
            </p>
          </>
        }
      />
      <hr />
      <ContentItem
        type='samsung'
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        title='Samsung'
        text={
          <>
            <p>
              1. Go to <b>Settings</b>.
            </p>
            <p>
              2. Tap on the <b>Biometric Data & Security</b>.
            </p>
            <p>
              3. Go to the <b>Install Unknown Apps</b>.
            </p>
            <p>
              4. Find the <b>Browser App</b>.
            </p>
            <p>
              5. Use the toggle switch to <b>Allow the App</b>.
            </p>
          </>
        }
      />
      <hr />
      <ContentItem
        type='other'
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        title='Other'
        text={
          <>
            <p>
              1. Go to <b>Settings</b>.
            </p>
            <p>
              2. Click the <b>Security & Privacy</b> option.
            </p>
            <p>
              3. Scroll down and tap on <b>Install Unknown Apps</b>. <br />
              If it's not there, try under <b>More</b>.
            </p>
            <p>
              4. You should see a list of Apps, please find the{' '}
              <b>Browser app</b> and tap on it,
            </p>
            <p>
              5. Select <b>Allow from this Source</b>.
            </p>
          </>
        }
      />
      <hr />
    </>
  );
}
