import './index.scss';
import {useCallback, useState} from 'react';
import {isIOS} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {
  setDownloading,
  setDownloadProgress,
} from '../../../../modules/privateDistribution/actions';
import {Android} from './android';
import {Ios} from './ios';
import {
  selectDownloadProgress,
  selectIsDownloading,
  selectPin,
} from '../../../../modules/privateDistribution/selectors';
import axios from 'axios';
import saveAs from 'file-saver';
import {setError} from '../../../../modules/errors/actions';
import {DownloadProgress} from './downloadProgress';

export function Download(): JSX.Element {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {hash} = useParams();

  const [activeItem, setActiveItem] = useState<string>('default');

  const isDownloading = useSelector(selectIsDownloading);
  const pin = useSelector(selectPin);
  const progress = useSelector(selectDownloadProgress);

  const handleDownload = useCallback(() => {
    if (hash && !isDownloading) {
      if (isIOS) {
        window.open(
          `itms-services://?action=download-manifest&url=${window.origin}/api/v2/manifest/${hash}`
        );
      } else {
        dispatch(setDownloading(true));
        axios
          .get(
            `${
              process.env.REACT_APP_REDIRECT_URL
            }/api/download/distribution/${hash}/${isIOS ? 'IOS' : 'ANDROID'}${
              pin ? '?pin=' + pin : ''
            }`,
            {
              responseType: 'arraybuffer',
              onDownloadProgress: progressEvent => {
                const progressValue = progressEvent.total
                  ? Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  : 0;
                dispatch(
                  setDownloadProgress({
                    total: progressEvent.total ?? 0,
                    percentage: progressValue,
                    downloaded: progressEvent.loaded,
                  })
                );
              },
            }
          )
          .then(response => {
            const file = new Blob([response.data]);
            saveAs(file, `${hash}.${isIOS ? 'ipa' : 'apk'}`);
          })
          .catch(error => dispatch(setError(error)))
          .finally(() => dispatch(setDownloading(false)));
      }
    }
  }, [isDownloading, hash, pin]);

  return (
    <div role='download-on-mobile'>
      <div
        className={`download btn ${isDownloading ? 'disabled' : ''}`}
        onClick={handleDownload}
      >
        {t('download')}
      </div>
      {isDownloading && progress && <DownloadProgress progress={progress} />}
      <div className='instructions'>
        {isIOS ? (
          <Ios />
        ) : (
          <Android activeItem={activeItem} setActiveItem={setActiveItem} />
        )}
      </div>
    </div>
  );
}
