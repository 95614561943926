import {TrashDeleteIcon} from '@betting/oui-icons';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Loading} from '../../../../../../common/components/loading';
import {ClientDto} from '../../../../../../common/types/api';
import {capitalizeFirstLetter} from '../../../../../../common/utils/stringUtil';
import {
  clearUploadedImage,
  deleteBrandingImage,
  uploadBrandingImage,
} from '../../../../../../modules/privateDistribution/actions';
import {
  selectIsLoading,
  selectUploadedImage,
} from '../../../../../../modules/privateDistribution/selectors';
import {Distribution} from '../../../../../../modules/privateDistribution/types';

import './index.scss';

interface IProps {
  type: string;
  client: ClientDto;
  distribution: Distribution;
}

export function BrandingElement({
  type,
  client,
  distribution,
}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [imageSrc, setImageSrc] = useState<string>();

  const imageUploaded = useSelector(selectUploadedImage);
  const loading = useSelector(selectIsLoading);

  useEffect(() => {
    setImageSrc(distribution.branding[type]?.url);
  }, []);

  const handleUploadFile = (data: FileList | null) => {
    if (data?.length && client.id) {
      dispatch(uploadBrandingImage(client.id, type, data[0]));
    }
  };

  const handleDeleteImage = () => {
    if (client.id) {
      dispatch(deleteBrandingImage(client.id, type));
      setImageSrc(undefined);
    }
  };

  useEffect(() => {
    if (imageUploaded && imageUploaded.type === type) {
      setImageSrc(URL.createObjectURL(imageUploaded.file));
    }
  }, [imageUploaded, type]);

  useEffect(() => {
    dispatch(clearUploadedImage());
  });

  return (
    <div className={`branding-element ${type}`}>
      <label>{capitalizeFirstLetter(t(type).toLowerCase())}</label>
      <img
        className='template'
        src={`/images/${type.toLowerCase()}-template.svg`}
      />
      <div className='file'>
        {loading === type ? (
          <Loading />
        ) : (
          <>{imageSrc ? <img className='preview' src={imageSrc} /> : null}</>
        )}
      </div>
      <div className='actions'>
        <input
          role='upload-file'
          className='input-form'
          id={type}
          type='file'
          multiple={false}
          accept='image/png, image/gif, image/jpeg'
          hidden
          onChange={event => handleUploadFile(event.target.files)}
        />
        <div
          className='secondary-button new-file'
          onClick={() => document.getElementById(type)?.click()}
          onKeyDown={e =>
            e.key === 'Enter' && document.getElementById(type)?.click()
          }
        >
          {t('new-file')}
        </div>
        <div
          className='secondary-button remove-file'
          onClick={handleDeleteImage}
        >
          <TrashDeleteIcon />
        </div>
      </div>
    </div>
  );
}
