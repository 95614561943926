import {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import useAmplifyAuth from '../../common/hooks/auth';
import {selectUser} from '../../modules/auth/selectors';
import {selectConfig} from '../../modules/config/selectors';
import './index.scss';

export function Login(): JSX.Element {
  useAmplifyAuth();

  const {t} = useTranslation();
  const navigate = useNavigate();

  const config = useSelector(selectConfig);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const handleSsoLogin = useCallback(() => {
    // Build custom login URL to avoid showing middle screen with Sign in with Sportradar ID
    window.open(
      `https://${config?.Auth.oauth.domain}/oauth2/authorize?identity_provider=Sportradar&redirect_uri=` +
        `${window.location.origin}/login&response_type=CODE&client_id=${config?.Auth.userPoolWebClientId}` +
        '&scope=aws.cognito.signin.user.admin%20email%20openid',
      '_self'
    );
  }, []);

  return (
    <div className='login-box'>
      <div className='login-wrapper'>
        <div className='logo'>
          <img alt='logo' src='./images/logo.svg' />
        </div>

        <div className='primary-button' onClick={handleSsoLogin}>
          <div>{t('login-sso')}</div>
        </div>
      </div>
    </div>
  );
}
