import {ChevronUpIcon, ChevronDownIcon} from '@betting/oui-icons';

interface IProps {
  title: string;
  text: JSX.Element;
  activeItem: string;
  setActiveItem: (item: string) => void;
  type: string;
}

export function ContentItem({
  title,
  text,
  activeItem,
  setActiveItem,
  type,
}: IProps): JSX.Element {
  return (
    <div className='content-section'>
      <h3>{title}</h3>
      {activeItem === type ? (
        <ChevronUpIcon className='icon' onClick={() => setActiveItem('')} />
      ) : (
        <ChevronDownIcon className='icon' onClick={() => setActiveItem(type)} />
      )}
      {activeItem === type && <div className='content-text'>{text}</div>}
    </div>
  );
}
