import {API} from 'aws-amplify';
import {put, takeEvery} from 'redux-saga/effects';
import {Language} from '../../common/types/api';
import {clearError, setError} from '../errors/actions';
import {setLanguages} from './actions';
import {LanguagesActionTypes} from './types';

export function* getLanguages() {
  try {
    const response = yield API.get('integrity', '/language', {}).then(
      (response: Language[]) => response
    );
    yield put(setLanguages(response));
    yield put(clearError());
  } catch (e: unknown) {
    yield put(setError((e as Error).message));
  }
}

export function* listenForAllLanguagesAction() {
  yield takeEvery(LanguagesActionTypes.GET, getLanguages);
}
