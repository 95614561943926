import {
  Branding,
  BrandingRequest,
  Distribution,
  DistributionActionTypes,
  LoginData,
  LoginResponse,
  Progress,
} from './types';

export function fetchDistribution(id: number) {
  return {
    type: DistributionActionTypes.GET,
    id,
  };
}

export function setDistribution(distribution: Distribution) {
  return {
    type: DistributionActionTypes.SET,
    distribution,
  };
}

export function clearDistribution() {
  return {
    type: DistributionActionTypes.CLEAR,
  };
}

export function enableOrDisableDistribution(id: number, enable: boolean) {
  return {
    type: DistributionActionTypes.ENABLE,
    payload: {id, enable},
  };
}

export function updatePin(id: number, pin: number) {
  return {
    type: DistributionActionTypes.PIN,
    payload: {id, pin},
  };
}

export function uploadBrandingImage(id: number, type: string, file: File) {
  return {
    type: DistributionActionTypes.UPLOAD_BRANDING,
    payload: {id, type, file},
  };
}

export function clearUploadedImage() {
  return {
    type: DistributionActionTypes.CLEAR_BRANDING_IMAGE,
  };
}

export function updateBrandingColor(id: number, body: BrandingRequest) {
  return {
    type: DistributionActionTypes.UPDATE_BRANDING_COLOR,
    payload: {id, body},
  };
}

export function deleteBrandingImage(id: number, type: string) {
  return {
    type: DistributionActionTypes.DELETE_BRANDING,
    payload: {id, type},
  };
}

export function addClientEmail(id: number, email: string) {
  return {
    type: DistributionActionTypes.ADD_CLIENT_EMAIL,
    payload: {id, email},
  };
}

export function removeClientEmail(id: number, email: string) {
  return {
    type: DistributionActionTypes.REMOVE_CLIENT_EMAIL,
    payload: {id, email},
  };
}

export function removeAllClientEmails(id: number) {
  return {
    type: DistributionActionTypes.REMOVE_ALL_CLIENT_EMAILS,
    id,
  };
}

export function uploadUserList(id: number, file: File) {
  return {
    type: DistributionActionTypes.UPLOAD_USER_LIST,
    payload: {file, id},
  };
}

export function sendEmailToOne(id: number, email: string) {
  return {
    type: DistributionActionTypes.SEND_ONE_EMAIL,
    payload: {id, email},
  };
}

export function sendEmailToAll(id: number) {
  return {
    type: DistributionActionTypes.SEND_ALL_EMAIL,
    id,
  };
}

export function fetchBranding(hash: string) {
  return {
    type: DistributionActionTypes.GET_BRANDING,
    hash,
  };
}

export function setBranding(branding: Branding) {
  return {
    type: DistributionActionTypes.SET_BRANDING,
    branding,
  };
}

export function uploadImageSuccess(type: string, file: File) {
  return {
    type: DistributionActionTypes.UPLOAD_IMAGE_SUCCESS,
    payload: {type, file},
  };
}

export function clearUploadingImage() {
  return {
    type: DistributionActionTypes.CLEAR_IMAGE,
  };
}

export function setLoading(type: string) {
  return {
    type: DistributionActionTypes.SET_LOADING,
    payload: {type},
  };
}

export function clearLoading() {
  return {
    type: DistributionActionTypes.CLEAR_LOADING,
  };
}

export function login(data: LoginData) {
  return {
    type: DistributionActionTypes.LOGIN,
    data,
  };
}

export function setUser(user: LoginResponse) {
  return {
    type: DistributionActionTypes.SET_USER,
    user,
  };
}

export function downloadApp(platform: string, hash: string, pin?: number) {
  return {
    type: DistributionActionTypes.GET_APP,
    payload: {platform, hash, pin},
  };
}

export function verifyPin(hash: string) {
  return {
    type: DistributionActionTypes.VERIFY_PIN,
    hash,
  };
}

export function setPin(pin: boolean | number) {
  return {
    type: DistributionActionTypes.SET_PIN,
    pin,
  };
}

export function setDownloading(status: boolean) {
  return {
    type: DistributionActionTypes.SET_DOWNLOADING,
    status,
  };
}

export function clearWasImageUploaded() {
  return {
    type: DistributionActionTypes.CLEAR_UPLOAD_IMAGE,
  };
}

export function setDownloadProgress(progress: Progress) {
  return {
    type: DistributionActionTypes.SET_DOWNLOAD_PROGRESS,
    payload: progress,
  };
}
