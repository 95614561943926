import {ChangeEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import './index.scss';

interface IProps {
  message: string;
  deleteAction(): void;
  cancelAction(): void;
  multiple?: boolean;
  title?: string;
}

export function DeleteModal({
  message,
  deleteAction,
  cancelAction,
  multiple = false,
  title,
}: Readonly<IProps>) {
  const {t} = useTranslation();

  const [confirmText, setConfirmText] = useState<string>('');

  if (!title) {
    title = t('delete-modal-title');
  }

  return (
    <div
      className={`delete-modal ${multiple ? 'multiple' : ''}`}
      role='delete-modal'
    >
      <h2 className='subhead'>{title}</h2>
      <div role='message'>{message}</div>
      {multiple && (
        <div className='confirm'>
          <span className='confirm-text'>{t('confirm')}</span>
          <span className='delete-text'>{t('delete-everything')}</span>
          <div className='form'>
            <div className='form-group'>
              <input
                className='input-form'
                type='text'
                role='delete-input'
                value={confirmText}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmText(e.target.value)
                }
              />
            </div>
          </div>
        </div>
      )}
      <div className='actions'>
        <button className='gray-button' onClick={cancelAction}>
          {t('cancel')}
        </button>
        <button
          className='delete-button'
          disabled={multiple && confirmText !== t('delete-everything')}
          onClick={deleteAction}
          role='delete-button'
        >
          {multiple ? t('delete-all') : t('delete')}
        </button>
      </div>
    </div>
  );
}
