import {AnyAction} from 'redux';
import {ClientsActionTypes, ClientsState} from './types';

export const initialState: ClientsState = {
  clients: [],
  files: [],
  loading: false,
  message: undefined,
  links: undefined,
  page: 0,
};

export function reducer(state: ClientsState = initialState, action: AnyAction) {
  switch (action.type) {
    case ClientsActionTypes.SET:
      return {
        ...state,
        clients: action.payload,
      };
    case ClientsActionTypes.UPDATE:
      return {
        ...state,
        clients: state.clients.map(client =>
          client.id !== action.payload.id ? client : action.payload
        ),
      };
    case ClientsActionTypes.SET_CLIENT_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case ClientsActionTypes.REMOVE_CLIENT_FILE:
      return {
        ...state,
        files: state.files.filter(file => file !== action.payload),
      };
    case ClientsActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ClientsActionTypes.SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case ClientsActionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        message: undefined,
      };
    case ClientsActionTypes.SET_APPLICATION_LINKS:
      return {
        ...state,
        links: action.payload,
      };
    case ClientsActionTypes.SET_PAGE:
      return {
        ...state,
        page: action.pageNumber,
      };
    case ClientsActionTypes.REMOVE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter(client => client.id !== action.payload),
      };
    default:
      return state;
  }
}
