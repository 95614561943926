import {ChangeEvent, useCallback, useState} from 'react';
import {FieldValues, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from '../../../../../../common/utils/stringUtil';
import {Distribution} from '../../../../../../modules/privateDistribution/types';

import './index.scss';

interface IProps {
  distribution: Distribution;
  type: string;
  register: UseFormRegister<FieldValues>;
  setDisabled: (value: boolean) => void;
}

export function BrandingColors({
  distribution,
  type,
  register,
  setDisabled,
}: IProps): JSX.Element {
  const {t} = useTranslation();

  const [currentColor, setCurrentColor] = useState<string>(
    distribution.branding[type]?.color &&
      distribution.branding[type]?.color !== ''
      ? distribution.branding[type]?.color
      : '#00003c'
  );

  const handleChangeCurrentColor = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentColor(event.target.value);
      if (
        event.target.value &&
        event.target.value !== distribution.branding[type]?.color
      ) {
        setDisabled(false);
      }
    },
    [distribution]
  );

  return (
    <div className={`colors ${type}`}>
      <div className='form-group'>
        <label>
          {type === 'BACKGROUND'
            ? capitalizeFirstLetter(t('background-color'))
            : `${t(type.toLowerCase())} ${t('background-color')}`}
        </label>
        <input
          className='input-form color'
          id={`${type}-color`}
          defaultValue={distribution.branding[type]?.color ?? ''}
          placeholder='#00003C'
          {...register(`${type}_COLOR`)}
          onChange={handleChangeCurrentColor}
        />
      </div>
      <div className='color-preview-wrapper'>
        <div
          className='color-preview'
          style={{
            background: currentColor,
          }}
        ></div>
      </div>
    </div>
  );
}
