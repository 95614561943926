import {Close2Icon} from '@betting/oui-icons';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Toast} from '../../../../common/components/toast';
import {ClientDto} from '../../../../common/types/api';
import {
  clearMessage,
  createSoftwareKey,
  resetSoftwareKey,
  sendKeyToClient,
} from '../../../../modules/clients/actions';
import {selectMessage} from '../../../../modules/clients/selectors';

import './index.scss';

interface IProps {
  client: ClientDto;
}

export function SoftwareDownloadKey({client}: IProps): JSX.Element {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const success_message = useSelector(selectMessage);

  const handleGenerateNewKey = useCallback(() => {
    if (client.id) {
      dispatch(createSoftwareKey(client.id));
    }
  }, [client]);

  const handleResetKey = useCallback(() => {
    if (client.id) {
      dispatch(resetSoftwareKey(client.id));
    }
  }, [client]);

  const handleSendToClient = useCallback(() => {
    if (client.id) {
      dispatch(sendKeyToClient(client.id, t('success_send_to_client')));
    }
  }, []);

  const handleOnClose = useCallback(() => {
    dispatch(clearMessage());
  }, []);

  return (
    <div className='section box'>
      <div className='software-key'>
        <div className='subsection input'>
          <div className='head'>
            <h2 className='subhead'>{t('software-download-key')}</h2>
          </div>
          <div className='body'>
            <label>{t('client-download-key')}</label>
            <div className='inline-group'>
              <div className='download-key' role='client-download-key'>
                {client.downloadKey}
                <div
                  className='remove'
                  onClick={handleResetKey}
                  role='reset-key-button'
                >
                  <Close2Icon />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='subsection buttons'>
          <button
            role='send-to-client-button'
            className='primary-button send-client'
            disabled={client.downloadKey === null || !client.emails?.length}
            onClick={handleSendToClient}
          >
            {t('send-to-client')}
          </button>
          <div
            className='gray-button'
            onClick={handleGenerateNewKey}
            role='generate-key-button'
          >
            {t('generate-key')}
          </div>
        </div>
      </div>
      {success_message && (
        <Toast
          message={success_message}
          close={true}
          onCloseAction={handleOnClose}
          additionalClass='info-toast'
        />
      )}
    </div>
  );
}
