import {AnyAction} from 'redux';
import {
  AuthState,
  FETCH_USER_FAILURE,
  FETCH_USER_INIT,
  FETCH_USER_SUCCESS,
  RESET_USER,
} from './types';

export const initialState: AuthState = {
  loading: false,
  user: undefined,
  error: false,
};

export function reducer(state: AuthState = initialState, action: AnyAction) {
  switch (action.type) {
    case FETCH_USER_INIT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        user: null,
        error: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: action.payload,
      };
    case RESET_USER:
      return {
        ...state,
        loading: false,
        error: false,
        user: undefined,
      };
    default:
      return state;
  }
}
