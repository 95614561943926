import {ReduxState} from '../../common/types/state';

export function selectClients(state: ReduxState) {
  return state.clients.clients;
}

export function selectClientFiles(state: ReduxState) {
  return state.clients.files;
}

export function selectIsLoading(state: ReduxState) {
  return state.clients.loading;
}

export function selectMessage(state: ReduxState) {
  return state.clients.message;
}

export function selectApplicationLinks(state: ReduxState) {
  return state.clients.links;
}

export function selectPage(state: ReduxState) {
  return state.clients.page;
}
