import {combineReducers, configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {ReduxState} from '../common/types/state';
import {all} from 'redux-saga/effects';

import {reducer as authReducer} from '../modules/auth/reducer';
import {reducer as configReducer} from '../modules/config/reducer';
import {reducer as clientsReducer} from '../modules/clients/reducer';
import {reducer as errorReducer} from '../modules/errors/reducer';
import {reducer as languagesReducer} from '../modules/languages/reducer';
import {reducer as activitiesReducer} from '../modules/activities/reducer';
import {reducer as distributionReducer} from '../modules/privateDistribution/reducer';

import {listenForAllConfigAction} from '../modules/config/sagas';
import {listenForAllClientsAction} from '../modules/clients/sagas';
import {listenForAllLanguagesAction} from '../modules/languages/sagas';
import {listenForAllActivitiesAction} from '../modules/activities/sagas';
import {listenForAllDistributionAction} from '../modules/privateDistribution/sagas';

const sagaMidleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    listenForAllConfigAction(),
    listenForAllClientsAction(),
    listenForAllLanguagesAction(),
    listenForAllActivitiesAction(),
    listenForAllDistributionAction(),
  ]);
}

const rootReducer = combineReducers<ReduxState>({
  auth: authReducer,
  config: configReducer,
  clients: clientsReducer,
  error: errorReducer,
  languages: languagesReducer,
  activities: activitiesReducer,
  distribution: distributionReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMidleware],
  // Turn off devTools in production
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMidleware.run(rootSaga);
