import {ChevronRightIcon, PlusAddIcon} from '@betting/oui-icons';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ClientDto} from '../../common/types/api';
import {modalStyles} from '../../config/modal';
import {fetchClients, setLoading, setPage} from '../../modules/clients/actions';
import {
  selectClients,
  selectIsLoading,
  selectPage,
} from '../../modules/clients/selectors';
import {NewClient} from './components/addClient';
import {Pagination} from '../../common/components/pagination';
import Modal from 'react-modal';

import './index.scss';
import {useNavigate} from 'react-router';
import {Loading} from '../../common/components/loading';

export function ClientList(): JSX.Element {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clients = useSelector(selectClients);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (!clients?.length) {
      dispatch(setLoading(true));
      dispatch(fetchClients());
    }
  }, []);

  const clientsPerPage = 10;
  const numberOfPages = Math.ceil(clients.length / clientsPerPage);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const page = useSelector(selectPage);

  const startIndex = page * clientsPerPage;

  const handlePageChange = useCallback(
    (selectedItem: {selected: number}) =>
      dispatch(setPage(selectedItem.selected)),
    [page]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className='clients' role='client-list'>
      <h1>{t('client-list')}</h1>
      <div className='outline-button' onClick={() => setIsModalOpen(true)}>
        <PlusAddIcon height={18} width={18} className='add-client' />
        {t('add-client-button')}
      </div>
      {!clients.length ? (
        <div>{t('no-clients')}</div>
      ) : (
        clients
          .slice(startIndex, startIndex + clientsPerPage)
          .map((client: ClientDto) => (
            <div
              key={client.name}
              className='client'
              onClick={() => navigate(`/client/${client.id}`)}
            >
              {client.name}
              <ChevronRightIcon className='more-icon' height={14} width={18} />
            </div>
          ))
      )}
      {numberOfPages > 1 && (
        <Pagination
          numberOfPages={numberOfPages}
          pageNumber={page}
          handlePageChange={handlePageChange}
        />
      )}

      <Modal
        isOpen={isModalOpen}
        style={modalStyles}
        onRequestClose={() => setIsModalOpen(false)}
        ariaHideApp={false}
      >
        <NewClient setIsModalOpen={setIsModalOpen} />
      </Modal>
    </div>
  );
}
