import {ErrorActionTypes} from './types';

export function setError(message: string) {
  return {
    type: ErrorActionTypes.SET,
    payload: message,
  };
}

export function clearError() {
  return {
    type: ErrorActionTypes.CLEAR,
  };
}
