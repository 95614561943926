import {ChevronLeftIcon, ExitRightIcon} from '@betting/oui-icons';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {selectUser} from '../../../modules/auth/selectors';
import useAmplifyAuth from '../../hooks/auth';
import {parseUsernameFromEmail} from './utils/parseUsernameFromEmail';

import './index.scss';

interface IProps {
  showUser?: boolean;
}

export function Navbar({showUser = true}: IProps): JSX.Element {
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  return (
    <div className='navbar'>
      {window.location.pathname === '/' ? (
        <img alt='logo' role='logo' src='/images/logo-white.svg' />
      ) : (
        <ChevronLeftIcon
          className='icon back'
          onClick={() =>
            window.history.state && window.history.state.idx > 0
              ? navigate(-1)
              : navigate('/')
          }
        />
      )}
      {showUser && (
        <div className='controls'>
          <div className='username'>
            {parseUsernameFromEmail(user?.attributes.email)}
          </div>
          <ExitRightIcon
            className='icon'
            onClick={useAmplifyAuth().handleSignout}
          />
        </div>
      )}
    </div>
  );
}
