import {t} from 'i18next';
import {round} from 'lodash';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Loading} from '../../../../common/components/loading';
import {ActivityDto, ClientDto} from '../../../../common/types/api';
import {parseDate} from '../../../../common/utils/parseDate';
import {
  clearActivitiesForClient,
  fetchActivitiesForClient,
  setLoading,
} from '../../../../modules/activities/actions';
import {selectActivitiesForClient} from '../../../../modules/activities/selectors';
import {selectIsLoading} from '../../../../modules/activities/selectors';

import './index.scss';

interface IProps {
  client: ClientDto;
}

export function ActivityList({client}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const activities = useSelector(selectActivitiesForClient);
  const isLoading = useSelector(selectIsLoading);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const lineHeight = 34;
  const screenHeight = window.innerHeight;
  const elementsPerPage = round(screenHeight / lineHeight);

  useEffect(() => {
    if (client.id && !activities?.length) {
      dispatch(setLoading(true));
      dispatch(fetchActivitiesForClient(client.id, 0, elementsPerPage));
    }
  }, [client, activities]);

  const isScrolling = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
      document.documentElement.offsetHeight -
        +(process.env.REACT_APP_SCROLLING_OFFSET ?? 5)
    ) {
      return;
    } else {
      client.id &&
        dispatch(
          fetchActivitiesForClient(client.id, currentPage + 1, elementsPerPage)
        );
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', isScrolling);
    return () => window.removeEventListener('scroll', isScrolling);
  }, [currentPage]);

  useEffect(() => {
    return () => {
      dispatch(clearActivitiesForClient());
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className='activity-list'>
      <div className='table-head'>
        <div className='created-date'>Created</div>
        <div className='type'>Type</div>
        <div className='message'>Message</div>
      </div>
      <div className='table'>
        {activities.length === 0 ? (
          <div className='no-activities'>{t('no-client-activities')}</div>
        ) : (
          activities.map((activity: ActivityDto) => (
            <div
              className='table-row'
              key={activity.created}
              role='activity-row'
            >
              <div className='created-date'>{parseDate(activity.created!)}</div>
              <div className='type'>{activity.type}</div>
              <div className='message'>{activity.activity}</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
