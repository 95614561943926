import {API, Auth} from 'aws-amplify';
import axios, {AxiosResponse} from 'axios';
import {put, takeLatest} from 'redux-saga/effects';
import {ApplicationLinksDto, ClientDto} from '../../common/types/api';
import {handleError} from '../../common/utils/errorHandling';
import {clearError, setError} from '../errors/actions';
import {ApiError} from '../errors/types';
import {
  fetchClients,
  removeClient,
  removeClientFile,
  setApplicationLinks,
  setClientFiles,
  setClients,
  setLoading,
  setMessage,
  updateClient,
} from './actions';
import {
  AddClientEmailPayload,
  ClientsActionTypes,
  CreateClientPayload,
  RemoveClientEmailPayload,
  GetClientFilesPayload,
  ResetSoftwareKeyPayload,
  UpdateClientDetailsPayload,
  UpdateClientReportingPayload,
  UploadClientFilePayload,
  UploadPublicKeyPayload,
  DeleteClientFilePayload,
  SendKeyToClientPayload,
  GetApplicationLinksPayload,
  DeleteClientPayload,
} from './types';

export function* getClients() {
  const response = yield API.get('integrity', '/client', {})
    .then((response: ClientDto[]) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setClients(response));
    yield put(clearError());
  }

  yield put(setLoading(false));
}

export function* createClient({
  payload: {client, navigate},
}: CreateClientPayload) {
  const response = yield API.post('integrity', '/client', {
    body: client,
  })
    .then((response: ClientDto) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(fetchClients());
    yield put(clearError());
    navigate(`/client/${response.id}`);
  }
}

export function* updateClientDetails({
  payload: {details, id},
}: UpdateClientDetailsPayload) {
  const response = yield API.put('integrity', `/client/${id}/details`, {
    body: details,
  })
    .then((response: ClientDto) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(updateClient(response));
    yield put(clearError());
  }
}

export function* updateClientReporting({
  payload: {reporting, id},
}: UpdateClientReportingPayload) {
  const response = yield API.put('integrity', `/client/${id}/settings`, {
    body: reporting,
  })
    .then((response: ClientDto) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(updateClient(response));
    yield put(clearError());
  }
}

export function* addClientEmail({payload: {email, id}}: AddClientEmailPayload) {
  const response = yield API.put(
    'integrity',
    `/client/${id}/email/${email}`,
    {}
  )
    .then((response: ClientDto) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(updateClient(response));
    yield put(clearError());
  }
}

export function* removeClientEmail({
  payload: {email, id},
}: RemoveClientEmailPayload) {
  const response = yield API.del(
    'integrity',
    `/client/${id}/email/${email}`,
    {}
  )
    .then((response: ClientDto) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(updateClient(response));
    yield put(clearError());
  }
}

export function* createSoftwareKey({payload: {id}}: ResetSoftwareKeyPayload) {
  const response = yield API.put('integrity', `/client/${id}/download-key`, {})
    .then((response: ClientDto) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(updateClient(response));
    yield put(clearError());
  }
}

export function* resetSoftwareKey({payload: {id}}: ResetSoftwareKeyPayload) {
  const response = yield API.put(
    'integrity',
    `/client/${id}/download-key/reset`,
    {}
  )
    .then((response: ClientDto) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(updateClient(response));
    yield put(clearError());
  }
}

// This request uses axios instead of amplify because FormData is not supported in amplify
export function* uploadPublicKey({
  payload: {file, id, baseUrl},
}: UploadPublicKeyPayload) {
  const formData = new FormData();
  formData.append('file', file);
  const session = yield Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const response = yield axios
    .put(`${baseUrl}/client/${id}/public-key`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse) => response.data)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(updateClient(response));
    yield put(clearError());
  }
}

// This request uses axios instead of amplify because FormData is not supported in amplify
export function* uploadClientFile({
  payload: {data, id, baseUrl, cb},
}: UploadClientFilePayload) {
  const formData = new FormData();
  formData.append('file', data.file!);

  const session = yield Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const response = yield axios
    .post(
      `${baseUrl}/client/${id}/file/${data.language}/${data.title}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: cb,
      }
    )
    .then((response: AxiosResponse) => response.data)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setClientFiles(response));
    yield put(clearError());
  }
}

export function* getFiles({payload: {client_id}}: GetClientFilesPayload) {
  const response = yield API.get(
    'integrity',
    `/client/${client_id}/file/all`,
    {}
  )
    .then((response: ClientDto[]) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setClientFiles(response));
    yield put(clearError());
  }
}

export function* deleteClientFile({
  payload: {file, id},
}: DeleteClientFilePayload) {
  const response = yield API.del(
    'integrity',
    `/client/${id}/file/${file.language}/${file.fileName}`,
    {}
  )
    .then((response: ClientDto) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(removeClientFile(file));
    yield put(clearError());
  }
}

export function* sendKeyToClient({
  payload: {client_id, success_message},
}: SendKeyToClientPayload) {
  const response = yield API.post(
    'integrity',
    `/download/${client_id}/send/email`,
    {}
  )
    .then(response => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setMessage(success_message));
    yield put(clearError());
  }
}

export function* getApplicationLinks({
  payload: key,
}: GetApplicationLinksPayload) {
  const response = yield API.get(
    'integrity-public',
    `/download/${key}/links`,
    {}
  )
    .then((response: ApplicationLinksDto) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setApplicationLinks(response));
    yield put(clearError());
  }
}

export function* deleteClient({payload}: DeleteClientPayload) {
  const response = yield API.del('integrity', `/client/${payload}`, {})
    .then(() => true)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(removeClient(payload));
    yield put(clearError());
    location.replace('/');
  }
}

export function* listenForAllClientsAction() {
  yield takeLatest(ClientsActionTypes.GET, getClients);
  yield takeLatest(ClientsActionTypes.CREATE, createClient);
  yield takeLatest(ClientsActionTypes.UPDATE_DETAILS, updateClientDetails);
  yield takeLatest(ClientsActionTypes.UPDATE_REPORTING, updateClientReporting);
  yield takeLatest(ClientsActionTypes.ADD_EMAIL, addClientEmail);
  yield takeLatest(ClientsActionTypes.REMOVE_EMAIL, removeClientEmail);
  yield takeLatest(ClientsActionTypes.CREATE_KEY, createSoftwareKey);
  yield takeLatest(ClientsActionTypes.RESET_KEY, resetSoftwareKey);
  yield takeLatest(ClientsActionTypes.UPLOAD_PUBLIC_KEY, uploadPublicKey);
  yield takeLatest(ClientsActionTypes.UPLOAD_CLIENT_FILE, uploadClientFile);
  yield takeLatest(ClientsActionTypes.FETCH_CLIENT_FILES, getFiles);
  yield takeLatest(ClientsActionTypes.DELETE_CLIENT_FILE, deleteClientFile);
  yield takeLatest(ClientsActionTypes.SEND_KEY_TO_CLIENT, sendKeyToClient);
  yield takeLatest(
    ClientsActionTypes.GET_APPLICATION_LINKS,
    getApplicationLinks
  );
  yield takeLatest(ClientsActionTypes.DELETE_CLIENT, deleteClient);
}
