import {AnyAction} from 'redux';
import {ActivitiesActionTypes, ActivitiesState} from './types';

export const initialState: ActivitiesState = {
  activities: [],
  loading: false,
};

export function reducer(
  state: ActivitiesState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case ActivitiesActionTypes.SET:
      return {
        ...state,
        activities: state.activities.concat(action.payload),
      };
    case ActivitiesActionTypes.CLEAR:
      return {
        ...state,
        activities: [],
      };
    case ActivitiesActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
