import {AnyAction} from 'redux';
import {ConfigActionTypes, ConfigState} from './types';

const initialState = {
  config: undefined,
};

export function reducer(state: ConfigState = initialState, action: AnyAction) {
  switch (action.type) {
    case ConfigActionTypes.SET:
      return {
        ...state,
        config: action.payload,
      };
    default:
      return state;
  }
}
