import {useTranslation} from 'react-i18next';
import {ClientDto} from '../../../../../common/types/api';
import Switch from 'react-switch';
import {switchProps} from '../../../../../config/switch';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  clearDistribution,
  enableOrDisableDistribution,
} from '../../../../../modules/privateDistribution/actions';
import {Distribution} from '../../../../../modules/privateDistribution/types';

import './index.scss';

interface IProps {
  client: ClientDto;
  distribution?: Distribution;
}

export function EnablePrivateDistribution({
  client,
  distribution,
}: IProps): JSX.Element {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [privateDistribution, setPrivateDistribution] =
    useState<boolean>(false);

  useEffect(() => {
    if (distribution) {
      setPrivateDistribution(distribution.enabled);
    }
  }, [distribution]);

  useEffect(() => {
    return () => {
      dispatch(clearDistribution());
    };
  }, []);

  const handleEnablingDistribution = useCallback(
    (value: boolean) => {
      if (client.id) {
        dispatch(enableOrDisableDistribution(client.id, value));
        setPrivateDistribution(value);
      }
    },
    [client]
  );

  return (
    <div className='box section distribution'>
      <div className='head'>
        <h2 className='subhead title'>{t('enable-private-distribution')}</h2>
        <h2 className='subhead id'>
          <Switch
            checked={privateDistribution}
            onChange={handleEnablingDistribution}
            {...switchProps}
          />
        </h2>
      </div>
    </div>
  );
}
