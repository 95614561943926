import {ClientDto} from '../../../../common/types/api';
import {ClientFiles} from '../clientFiles';
import {ClientPublicKey} from '../clientPublicKey';
import {DeleteClient} from '../deleteClient';
import {Details} from '../details';
import {Reporting} from '../reporting';
import {ReportingEmails} from '../reportingEmails';
import {SoftwareDownloadKey} from '../softwareKey';

interface IProps {
  client: ClientDto;
}

export function Settings({client}: Readonly<IProps>): JSX.Element {
  return (
    <div role='client-settings'>
      <Details client={client} />
      <Reporting client={client} />
      <ReportingEmails client={client} />
      <SoftwareDownloadKey client={client} />
      <ClientPublicKey client={client} />
      <ClientFiles client={client} />
      <DeleteClient client={client} />
    </div>
  );
}
