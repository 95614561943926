export function getSize(size: number) {
  switch (true) {
    case size < 1024:
      return `${size} B`;
    case size < 1024 * 1024:
      // Convert to kilobytes
      return `${(size / 1024).toFixed(2)} KB`;
    default:
      // Convert to megabytes
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
}
