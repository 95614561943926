import {ActivityDto} from '../../common/types/api';
import {ActivitiesActionTypes} from './types';

export function fetchActivitiesForClient(
  client_id: number,
  currentPage: number,
  elementsPerPage: number
) {
  return {
    type: ActivitiesActionTypes.GET,
    payload: {client_id, currentPage, elementsPerPage},
  };
}

export function setActivitiesForClient(activities: ActivityDto[]) {
  return {
    type: ActivitiesActionTypes.SET,
    payload: activities,
  };
}

export function clearActivitiesForClient() {
  return {
    type: ActivitiesActionTypes.CLEAR,
  };
}

export function setLoading(value: boolean) {
  return {
    type: ActivitiesActionTypes.SET_LOADING,
    payload: value,
  };
}
