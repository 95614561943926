import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './config/i18n';
import {Provider} from 'react-redux';
import {store} from './config/store';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<div> Loading </div>}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>
);
