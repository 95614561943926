import {AnyAction} from 'redux';
import {DistributionActionTypes, DistributionState} from './types';

export const initialState: DistributionState = {
  distribution: undefined,
  uploadedImage: undefined,
  loading: undefined,
  user: undefined,
  pin: undefined,
  downloading: false,
  wasImageUploaded: false,
  progress: undefined,
};

export function reducer(
  state: DistributionState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case DistributionActionTypes.SET:
      return {
        ...state,
        distribution: action.distribution,
      };
    case DistributionActionTypes.CLEAR:
      return {
        ...state,
        distribution: undefined,
      };
    case DistributionActionTypes.SET_BRANDING:
      return {
        ...state,
        distribution: {
          ...state.distribution,
          branding: action.branding,
        },
      };
    case DistributionActionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadedImage: action.payload,
        wasImageUploaded: true,
      };
    case DistributionActionTypes.CLEAR_UPLOAD_IMAGE:
      return {
        ...state,
        wasImageUploaded: false,
      };
    case DistributionActionTypes.CLEAR_IMAGE:
      return {
        ...state,
        uploadedImage: undefined,
      };
    case DistributionActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload.type,
      };
    case DistributionActionTypes.CLEAR_LOADING:
      return {
        ...state,
        loading: undefined,
      };
    case DistributionActionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case DistributionActionTypes.SET_PIN:
      return {
        ...state,
        pin: action.pin,
      };
    case DistributionActionTypes.SET_DOWNLOADING:
      return {
        ...state,
        downloading: action.status,
        progress: undefined,
      };
    case DistributionActionTypes.CLEAR_BRANDING_IMAGE:
      return {
        ...state,
        uploadedImage: undefined,
      };
    case DistributionActionTypes.SET_DOWNLOAD_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    default:
      return state;
  }
}
