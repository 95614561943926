import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Loading} from '../../../../common/components/loading';
import {ClientDto} from '../../../../common/types/api';
import {fetchDistribution} from '../../../../modules/privateDistribution/actions';
import {selectDistribution} from '../../../../modules/privateDistribution/selectors';
import {Branding} from './branding';
import {Details} from './details';
import {EnablePrivateDistribution} from './EnablePrivateDistribution';
import {UserList} from './userList';

interface IProps {
  client: ClientDto;
}

export function PrivateDistribution({client}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const distribution = useSelector(selectDistribution);

  useEffect(() => {
    if (client.id) {
      dispatch(fetchDistribution(client.id));
    }
  }, [client]);

  if (!distribution) {
    <Loading />;
  }

  return (
    <div className='private-distribution' role='private-distribution'>
      <EnablePrivateDistribution client={client} distribution={distribution} />
      {distribution?.enabled && (
        <>
          <Details client={client} distribution={distribution} />
          <Branding client={client} distribution={distribution} />
          <UserList client={client} distribution={distribution} />
        </>
      )}
    </div>
  );
}
