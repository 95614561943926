import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {Toast} from '../../../../common/components/toast';
import {ClientDto} from '../../../../common/types/api';
import {toUpperCase} from '../../../../common/utils/upperCase';
import {createClient} from '../../../../modules/clients/actions';

import './index.scss';

interface IProps {
  setIsModalOpen(value: boolean): void;
}
export function NewClient({setIsModalOpen}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
    formState: {isDirty, isValid, errors},
  } = useForm({mode: 'all'});

  const handleCreateClient = useCallback((data: ClientDto) => {
    if (data.name) {
      dispatch(createClient(data, navigate));
      setIsModalOpen(false);
    }
  }, []);

  return (
    <div className='new-client'>
      <h2 className='subhead'>{t('new-client')}</h2>
      <form>
        <div className='form'>
          <div className='form-group abbrevation'>
            <label>{t('abbrevation')}</label>
            <input
              className='input-form'
              id='abbreviation'
              {...register('abbrevation', {
                required: true,
                maxLength: {value: 4, message: t('to-long-abbr')},
              })}
              onInput={toUpperCase}
              role='abbr-input'
            />
          </div>
          <div className='form-group name'>
            <label>{t('full-name')}</label>
            <input
              className='input-form'
              id='name'
              {...register('name', {
                required: true,
              })}
            />
          </div>
        </div>
        {errors.abbrevation && errors.abbrevation.type === 'maxLength' && (
          <div role='error-toast'>
            <Toast
              message={errors.abbrevation.message}
              close={false}
              onCloseAction={() => {}}
              additionalClass='error-toast'
            />
          </div>
        )}
        <div className='controls'>
          <button
            className='primary-button new-client-button'
            onClick={handleSubmit(handleCreateClient)}
            disabled={!isValid || !isDirty}
            role='add-client'
          >
            {t('create')}
          </button>
        </div>
      </form>
    </div>
  );
}
