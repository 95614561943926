import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ClientDto} from '@common/types/api';
import {DeleteModal} from '../../../../common/components/deleteModal';
import Modal from 'react-modal';
import {modalStyles} from '../../../../config/modal';

import './index.scss';
import {deleteClient} from 'modules/clients/actions';

interface IProps {
  client: ClientDto;
}

export function DeleteClient({client}: Readonly<IProps>): JSX.Element {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const handleDelete = useCallback(() => {
    if (client.id) {
      dispatch(deleteClient(client.id));
    }
  }, [client]);

  return (
    <div className='box section delete-client'>
      <div className='content'>
        <div className='head'>
          <h2 className='subhead'>{t('delete-client')}</h2>
        </div>
        <p>{t('delete-client-description')}</p>
        <div className='actions'>
          <button
            className='primary-button delete-button'
            onClick={() => setIsDeleteModalOpen(true)}
          >
            {t('delete')}
          </button>
        </div>
      </div>
      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          style={modalStyles}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          ariaHideApp={false}
        >
          <DeleteModal
            message={t('delete-client-confirmation')}
            deleteAction={handleDelete}
            cancelAction={() => setIsDeleteModalOpen(false)}
            title={t('delete-client')}
          />
        </Modal>
      )}
    </div>
  );
}
