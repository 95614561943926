import {useEffect} from 'react';
import {isDesktop} from 'react-device-detect';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Loading} from '../../common/components/loading';
import {fetchBranding} from '../../modules/privateDistribution/actions';
import {selectDistribution} from '../../modules/privateDistribution/selectors';
import {DownloadOnDesktop} from './downloadOnDesktop';
import {DownloadOnMobile} from './downloadOnMobile';

import './index.scss';

export function DownloadMobileApp(): JSX.Element {
  const {hash} = useParams();
  const dispatch = useDispatch();

  const distribution = useSelector(selectDistribution);

  useEffect(() => {
    if (hash) {
      dispatch(fetchBranding(hash));
    }
  }, [hash]);

  if (!distribution) {
    <Loading />;
  }

  return (
    <div role='download-mobile'>
      {isDesktop ? (
        <DownloadOnDesktop branding={distribution?.branding} />
      ) : (
        <DownloadOnMobile branding={distribution?.branding} />
      )}
    </div>
  );
}
