import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';

i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('language') ?? 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });
