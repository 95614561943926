import {NavigateFunction} from 'react-router';
import {
  ApplicationLinksDto,
  ClientDto,
  ClientFileDto,
  DetailsRequestBody,
  ReportSettingsRequestBody,
} from '../../common/types/api';
import {ClientsActionTypes} from './types';
import {AxiosProgressEvent} from 'axios';

export function fetchClients() {
  return {
    type: ClientsActionTypes.GET,
  };
}

export function setClients(clients: ClientDto[]) {
  return {
    type: ClientsActionTypes.SET,
    payload: clients,
  };
}

export function createClient(client: ClientDto, navigate: NavigateFunction) {
  return {
    type: ClientsActionTypes.CREATE,
    payload: {client, navigate},
  };
}

export function updateClientDetails(details: DetailsRequestBody, id: number) {
  return {
    type: ClientsActionTypes.UPDATE_DETAILS,
    payload: {details, id},
  };
}

export function updateClientReporting(
  reporting: ReportSettingsRequestBody,
  id: number
) {
  return {
    type: ClientsActionTypes.UPDATE_REPORTING,
    payload: {reporting, id},
  };
}

export function addClientEmail(email: string, id: number) {
  return {
    type: ClientsActionTypes.ADD_EMAIL,
    payload: {
      email,
      id,
    },
  };
}

export function removeClientEmail(email: string, id: number) {
  return {
    type: ClientsActionTypes.REMOVE_EMAIL,
    payload: {
      email,
      id,
    },
  };
}

export function createSoftwareKey(id: number) {
  return {
    type: ClientsActionTypes.CREATE_KEY,
    payload: {id},
  };
}

export function resetSoftwareKey(id: number) {
  return {
    type: ClientsActionTypes.RESET_KEY,
    payload: {id},
  };
}

export function uploadPublicKey(file: File, id: number, baseUrl: string) {
  return {
    type: ClientsActionTypes.UPLOAD_PUBLIC_KEY,
    payload: {file, id, baseUrl},
  };
}

export function uploadClientFile(
  data: ClientFileDto,
  id: number,
  baseUrl: string,
  cb: (progressEvent: AxiosProgressEvent) => void
) {
  return {
    type: ClientsActionTypes.UPLOAD_CLIENT_FILE,
    payload: {data, id, baseUrl, cb},
  };
}

export function updateClient(client: ClientDto) {
  return {
    type: ClientsActionTypes.UPDATE,
    payload: client,
  };
}

export function fetchClientFiles(client_id: number) {
  return {
    type: ClientsActionTypes.FETCH_CLIENT_FILES,
    payload: {client_id},
  };
}

export function setClientFiles(files: ClientFileDto[]) {
  return {
    type: ClientsActionTypes.SET_CLIENT_FILES,
    payload: files,
  };
}

export function deleteClientFile(file: ClientFileDto, id: number) {
  return {
    type: ClientsActionTypes.DELETE_CLIENT_FILE,
    payload: {file, id},
  };
}

export function removeClientFile(file: ClientFileDto) {
  return {
    type: ClientsActionTypes.REMOVE_CLIENT_FILE,
    payload: file,
  };
}

export function setLoading(value: boolean) {
  return {
    type: ClientsActionTypes.SET_LOADING,
    payload: value,
  };
}

export function sendKeyToClient(client_id: number, success_message: string) {
  return {
    type: ClientsActionTypes.SEND_KEY_TO_CLIENT,
    payload: {client_id, success_message},
  };
}

export function setMessage(message: string) {
  return {
    type: ClientsActionTypes.SET_MESSAGE,
    payload: message,
  };
}

export function clearMessage() {
  return {
    type: ClientsActionTypes.CLEAR_MESSAGE,
  };
}

export function getApplicationLinks(key: string) {
  return {
    type: ClientsActionTypes.GET_APPLICATION_LINKS,
    payload: key,
  };
}

export function setApplicationLinks(links: ApplicationLinksDto) {
  return {
    type: ClientsActionTypes.SET_APPLICATION_LINKS,
    payload: links,
  };
}

export function downloadApplication(
  downloadKey: string,
  platform: string,
  downloadUrl: string,
  baseUrl: string
) {
  return {
    type: ClientsActionTypes.DOWNLOAD_APPLICATION,
    payload: {downloadKey, platform, downloadUrl, baseUrl},
  };
}

export function setPage(pageNumber: number) {
  return {
    type: ClientsActionTypes.SET_PAGE,
    pageNumber,
  };
}

export function deleteClient(clientId: number) {
  return {
    type: ClientsActionTypes.DELETE_CLIENT,
    payload: clientId,
  };
}

export function removeClient(clientId: number) {
  return {
    type: ClientsActionTypes.REMOVE_CLIENT,
    payload: clientId,
  };
}
