import {AnyAction} from 'redux';
import {ErrorActionTypes, ErrorState} from './types';

export const initialState: ErrorState = {
  message: undefined,
};

export function reducer(state: ErrorState = initialState, action: AnyAction) {
  switch (action.type) {
    case ErrorActionTypes.SET:
      return {
        ...state,
        message: action.payload,
      };
    case ErrorActionTypes.CLEAR:
      return {
        ...state,
        message: undefined,
      };
    default:
      return state;
  }
}
