export enum ErrorActionTypes {
  SET = '@errors/SET',
  CLEAR = '@errors/CLEAR',
}

export interface ErrorState {
  message?: string;
}

export interface ApiError {
  response: {
    data: {
      message: string | null;
      error: string;
    };
  };
}

export interface ArrayBufferError {
  response: {
    data: ArrayBuffer;
  };
}
