import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {login} from '../../../../modules/privateDistribution/actions';

export function Login(): JSX.Element {
  const {t} = useTranslation();
  const {register, handleSubmit} = useForm();
  const dispatch = useDispatch();
  const {hash} = useParams();

  const handleLogin = useCallback(data => {
    dispatch(login({pin: +data.pin, distributionHash: hash}));
  }, []);

  // Handle Done button on iOS
  document.addEventListener('focusout', () => handleSubmit(handleLogin)());

  return (
    <form>
      <div className='form'>
        <div className='form-group full'>
          <input
            role='email-input'
            className='input-form'
            id='email'
            placeholder={t('email')}
            {...register('email', {
              required: false,
            })}
            hidden
            onKeyDown={e => e.key === 'Enter' && handleSubmit(handleLogin)()}
          />
        </div>
        <div className='form-group full'>
          <label>{t('pin-number')}</label>
          <input
            role='pin-input'
            className='input-form'
            id='pin'
            type='number'
            placeholder={t('pin-number')}
            {...register('pin', {
              required: true,
            })}
            onKeyDown={e => e.key === 'Enter' && handleSubmit(handleLogin)()}
          />
        </div>
      </div>
    </form>
  );
}
