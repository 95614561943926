import {Language} from '../../common/types/api';
import {LanguagesActionTypes} from './types';

export function fetchLanguages() {
  return {
    type: LanguagesActionTypes.GET,
  };
}

export function setLanguages(languages: Language[]) {
  return {
    type: LanguagesActionTypes.SET,
    payload: {languages},
  };
}
