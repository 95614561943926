import {AwsConfig} from '../../common/types/aws';

export enum ConfigActionTypes {
  GET = '@config/GET_AWS_CONFIG',
  SET = '@config/SET_AWS_CONFIG',
}

export interface ConfigState {
  config?: AwsConfig;
}

export interface ApiConfig {
  region: string;
  clientId: string;
  webDomain: string;
}
