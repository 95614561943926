import {
  EmailIcon,
  SuccessCheckCircleIcon,
  TrashDeleteIcon,
} from '@betting/oui-icons';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {DeleteModal} from '../../../../../../common/components/deleteModal';
import {ClientDto} from '../../../../../../common/types/api';
import {modalStyles} from '../../../../../../config/modal';
import {
  removeAllClientEmails,
  removeClientEmail,
  sendEmailToAll,
  sendEmailToOne,
} from '../../../../../../modules/privateDistribution/actions';
import {DistributionUser} from '../../../../../../modules/privateDistribution/types';
import Modal from 'react-modal';
import {lastModified} from '../../../../../../common/utils/parseDate';
import {differenceInMinutes} from 'date-fns';

import './index.scss';

interface IProps {
  client: ClientDto;
  users: DistributionUser[];
}

export function UserTable({users, client}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isDeleteAllDialogOpen, setIsDeleteAllDialogOpen] =
    useState<boolean>(false);
  const [deleteEmail, setDeleteEmail] = useState<string>();

  const handleDeleteEmail = useCallback(() => {
    if (client.id && deleteEmail) {
      dispatch(removeClientEmail(client.id, deleteEmail));
      setIsDeleteDialogOpen(false);
    }
  }, [client, deleteEmail]);

  const handleDeleteAllEmails = useCallback(() => {
    if (client.id) {
      dispatch(removeAllClientEmails(client.id));
      setIsDeleteAllDialogOpen(false);
    }
  }, [client]);

  const handleResendEmail = useCallback(
    (email: string) => {
      if (client.id) {
        dispatch(sendEmailToOne(client.id, email));
      }
    },
    [client]
  );

  const handleResendAllEmails = useCallback(() => {
    if (client.id) {
      dispatch(sendEmailToAll(client.id));
    }
  }, [client]);

  const handleOpenDeleteEmailDialog = useCallback((userEmail: string) => {
    setIsDeleteDialogOpen(true);
    setDeleteEmail(userEmail);
  }, []);

  return (
    <div className='user-table'>
      <div className='table-head'>
        <div className='email'>
          <b>{t('email')}</b>
        </div>
        <div
          className='mail'
          onClick={handleResendAllEmails}
          role='send-all-button'
        >
          <EmailIcon />
          <span>{t('send-invites')}</span>
        </div>
        <div
          className='delete'
          onClick={() => setIsDeleteAllDialogOpen(true)}
          role='delete-all-button'
        >
          <TrashDeleteIcon />
        </div>
      </div>
      <div className='table'>
        {users.map((user: DistributionUser) => (
          <div className='row' key={user.email}>
            <div className='email'>
              <span>{user.email}</span>
              <span className='modified'>
                {user.modified && lastModified(user.modified)}
              </span>
            </div>
            <div
              className={`mail ${
                differenceInMinutes(new Date(), user.modified) < 5 ? 'now' : ''
              }`}
              onClick={() => handleResendEmail(user.email)}
              role={`send-${user.email}-button`}
            >
              {differenceInMinutes(new Date(), user.modified) < 5 ? (
                <SuccessCheckCircleIcon />
              ) : (
                <EmailIcon />
              )}
              <span>
                {differenceInMinutes(new Date(), user.modified) < 5
                  ? t('resent')
                  : t('resend')}
              </span>
            </div>
            <div
              className='delete'
              onClick={() => handleOpenDeleteEmailDialog(user.email)}
              role={`delete-${user.email}-button`}
            >
              <TrashDeleteIcon />
            </div>

            <Modal
              isOpen={isDeleteDialogOpen && deleteEmail === user.email}
              style={modalStyles}
              onRequestClose={() => setIsDeleteDialogOpen(false)}
              ariaHideApp={false}
            >
              <DeleteModal
                message={t('delete-email-warning')}
                deleteAction={() => handleDeleteEmail()}
                cancelAction={() => setIsDeleteDialogOpen(false)}
              />
            </Modal>
          </div>
        ))}
      </div>
      <Modal
        isOpen={isDeleteAllDialogOpen}
        style={modalStyles}
        onRequestClose={() => setIsDeleteAllDialogOpen(false)}
        ariaHideApp={false}
      >
        <DeleteModal
          title={t('delete-all-emails')}
          message={t('delete-multiple-warning')}
          multiple={true}
          deleteAction={() => handleDeleteAllEmails()}
          cancelAction={() => setIsDeleteAllDialogOpen(false)}
        />
      </Modal>
    </div>
  );
}
