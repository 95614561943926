import {AnyAction} from 'redux';
import {LanguagesActionTypes, LanguagesState} from './types';

export const initialState = {
  languages: [],
};

export function reducer(
  state: LanguagesState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case LanguagesActionTypes.SET:
      return {
        languages: action.payload.languages,
      };
    default:
      return state;
  }
}
