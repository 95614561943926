import {ApiError} from '../../modules/errors/types';

export function handleError(e: ApiError) {
  return {
    error:
      e.response?.data.message ??
      e.response?.data.error ??
      e.response?.data ??
      e,
  };
}
