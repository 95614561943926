import {ActivityDto} from '../../common/types/api';
import {fetchActivitiesForClient} from './actions';

export enum ActivitiesActionTypes {
  GET = '@activities/GET',
  SET = '@activities/SET',
  CLEAR = '@activities/CLEAR',
  SET_LOADING = '@activities/SET_LOADING',
}

export interface ActivitiesState {
  activities: ActivityDto[];
  loading: boolean;
}

export type GetActivitiesForClientPayload = ReturnType<
  typeof fetchActivitiesForClient
>;
