import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Loading} from '../../common/components/loading';
import {ClientDto} from '../../common/types/api';
import {fetchClients} from '../../modules/clients/actions';
import {selectClients} from '../../modules/clients/selectors';
import {ActivityList} from './components/activityList';
import {PrivateDistribution} from './components/privateDistribution';
import {Settings} from './components/settings';

import './index.scss';

export function Client(): JSX.Element {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const clients = useSelector(selectClients);

  const [activeTab, setActiveTab] = useState<string>('settings');

  useEffect(() => {
    if (!clients?.length) {
      dispatch(fetchClients());
    }
  }, []);

  const renderData = useCallback(
    (client: ClientDto) => {
      switch (activeTab) {
        case 'activity':
          return (
            <div role='activity-list'>
              <ActivityList client={client} />
            </div>
          );
        case 'private-distribution':
          return <PrivateDistribution client={client} />;
        default:
          return <Settings client={client} />;
      }
    },
    [activeTab]
  );

  // for testing purpuses we cannot use query params
  const client = id
    ? clients.find(element => element.id === parseInt(id))
    : clients[0];

  if (!client) {
    return <Loading />;
  }

  return (
    <div className='clients' role='client'>
      <div className='tabs'>
        <div
          className={activeTab === 'settings' ? 'active' : ''}
          onClick={() => setActiveTab('settings')}
        >
          {t('settings')}
        </div>
        <div
          role='activity-tab'
          className={activeTab === 'activity' ? 'active' : ''}
          onClick={() => setActiveTab('activity')}
        >
          {t('activity')}
        </div>
        <div
          role='private-distribution'
          className={activeTab === 'private-distribution' ? 'active' : ''}
          onClick={() => setActiveTab('private-distribution')}
        >
          {t('distribution')}
        </div>
      </div>

      {renderData(client)}
    </div>
  );
}
