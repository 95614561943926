import {
  addClientEmail,
  deleteBrandingImage,
  downloadApp,
  enableOrDisableDistribution,
  fetchBranding,
  fetchDistribution,
  login,
  removeAllClientEmails,
  removeClientEmail,
  sendEmailToAll,
  sendEmailToOne,
  updateBrandingColor,
  updatePin,
  uploadBrandingImage,
  uploadUserList,
  verifyPin,
} from './actions';

export interface DistributionState {
  distribution?: Distribution;
  uploadedImage?: {
    type: string;
    file: File;
  };
  loading?: string;
  user?: LoginResponse;
  pin?: boolean | number;
  downloading: boolean;
  wasImageUploaded: boolean;
  progress?: Progress;
}

export interface Distribution {
  enabled: boolean;
  distributionFileName: string;
  pin: number;
  users: DistributionUser[];
  branding: Branding;
  distributionUrl: string;
}

export interface Branding {
  LOGO?: BrandingImage;
  BACKGROUND?: BrandingImage;
  ICON?: BrandingImage;
}

export interface BrandingRequest {
  LOGO: string;
  BACKGROUND: string;
  ICON: string;
}
interface BrandingImage {
  url: string;
  color: string;
}

export interface DistributionUser {
  email: string;
  modified: number;
}

export interface LoginData {
  email?: string;
  pin: number;
  distributionHash?: string;
}

export interface LoginResponse {
  email: string;
  accessToken: string;
}

export interface PinResponse {
  pin: boolean;
}

export interface Progress {
  percentage: number;
  total: number;
  downloaded: number;
}

export enum DistributionActionTypes {
  GET = '@distribution/GET',
  SET = '@distribution/SET',
  CLEAR = '@distribution/CLEAR',
  ENABLE = '@distribution/ENABLE',
  PIN = '@distribution/PIN',
  UPLOAD_BRANDING = '@distribution/UPLOAD_BRANDING',
  UPDATE_BRANDING_COLOR = '@distribution/UPDATE_BRANDING_COLOR',
  DELETE_BRANDING = '@distribution/DELETE_BRANDING',
  ADD_CLIENT_EMAIL = '@distribution/ADD_CLIENT_EMAIL',
  REMOVE_CLIENT_EMAIL = '@distribution/REMOVE_CLIENT_EMAIL',
  REMOVE_ALL_CLIENT_EMAILS = '@distribution/REMOVE_ALL_CLIENT_EMAILS',
  UPLOAD_USER_LIST = '@distribution/UPLOAD_USER_LIST',
  SEND_ONE_EMAIL = '@distribution/SEND_ONE_EMAIL',
  SEND_ALL_EMAIL = '@distribution/SEND_ALL_EMAIL',
  GET_BRANDING = '@distribution/GET_BRANDING',
  SET_BRANDING = '@distribution/SET_BRANDING',
  UPLOAD_IMAGE_SUCCESS = '@distribution/UPLOAD_IMAGE_SUCCESS',
  CLEAR_IMAGE = '@distribution/CLEAR_IMAGE',
  SET_LOADING = '@distribution/SET_LOADING',
  CLEAR_LOADING = '@distribution/CLEAR_LOADING',
  LOGIN = '@distribution/LOGIN',
  SET_USER = '@distribution/SET_USER',
  GET_APP = '@distribution/GET_APP',
  VERIFY_PIN = '@distribution/VERIFY_PIN',
  SET_PIN = '@distribution/SET_PIN',
  SET_DOWNLOADING = '@distribution/SET_DOWNLOADING',
  CLEAR_BRANDING_IMAGE = '@distribution/CLEAR_BRANDING_IMAGE',
  CLEAR_UPLOAD_IMAGE = '@distribution/CLEAR_UPLOAD_IMAGE',
  SET_DOWNLOAD_PROGRESS = '@distribution/SET_DOWNLOAD_PROGRESS',
}

export type GetDistributionPayload = ReturnType<typeof fetchDistribution>;
export type EnableDistributionPayload = ReturnType<
  typeof enableOrDisableDistribution
>;
export type UpdatePinPayload = ReturnType<typeof updatePin>;
export type UploadBrandingImagePayload = ReturnType<typeof uploadBrandingImage>;
export type UpdateBrandingColorPayload = ReturnType<typeof updateBrandingColor>;
export type DeleteBrandingImagePayload = ReturnType<typeof deleteBrandingImage>;
export type AddClientEmailPayload = ReturnType<typeof addClientEmail>;
export type RemoveClientEmailPayload = ReturnType<typeof removeClientEmail>;
export type RemoveAllClientEmailsPayload = ReturnType<
  typeof removeAllClientEmails
>;
export type UploadUserListPayload = ReturnType<typeof uploadUserList>;
export type SendOneEmailPayload = ReturnType<typeof sendEmailToOne>;
export type SendAllEmailsPayload = ReturnType<typeof sendEmailToAll>;
export type GetBrandingPayload = ReturnType<typeof fetchBranding>;
export type LoginPayload = ReturnType<typeof login>;
export type GetAppPayload = ReturnType<typeof downloadApp>;
export type VerifyPinPayload = ReturnType<typeof verifyPin>;
