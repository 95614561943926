import {API} from 'aws-amplify';
import {put, takeLatest} from 'redux-saga/effects';
import {ActivityDto} from '../../common/types/api';
import {handleError} from '../../common/utils/errorHandling';
import {clearError, setError} from '../errors/actions';
import {ApiError} from '../errors/types';
import {setActivitiesForClient, setLoading} from './actions';
import {ActivitiesActionTypes, GetActivitiesForClientPayload} from './types';

export function* getActivitiesForClient({
  payload: {client_id, currentPage, elementsPerPage},
}: GetActivitiesForClientPayload) {
  const response = yield API.get(
    'integrity',
    `/activity/${client_id}?limit=${elementsPerPage}&skip=${
      currentPage * elementsPerPage
    }`,
    {}
  )
    .then((response: ActivityDto[]) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setActivitiesForClient(response));
    yield put(clearError());
  }

  yield put(setLoading(false));
}

export function* listenForAllActivitiesAction() {
  yield takeLatest(ActivitiesActionTypes.GET, getActivitiesForClient);
}
