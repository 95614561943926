import {CloudDownloadIcon} from '@betting/oui-icons';
import axios from 'axios';
import saveAs from 'file-saver';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Loading} from '../../common/components/loading';
import {Navbar} from '../../common/components/navbar';
import {Toast} from '../../common/components/toast';
import {getApplicationLinks} from '../../modules/clients/actions';
import {selectApplicationLinks} from '../../modules/clients/selectors';
import {selectConfig} from '../../modules/config/selectors';
import {clearError, setError} from '../../modules/errors/actions';

import './index.scss';

export function Download(): JSX.Element {
  const {key} = useParams();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const applicationLinks = useSelector(selectApplicationLinks);
  const config = useSelector(selectConfig);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (key && !applicationLinks) {
      dispatch(getApplicationLinks(key));
    }
  }, []);

  const handleDownloadApplication = useCallback(
    async (platform: string, downloadUrl: string) => {
      if (key && config) {
        setIsDownloading(true);
        // Download file cannot be moved into another file as onDownloadProgress callback need to be set here
        await axios
          .get(`${config.API.endpoints[0].endpoint}${downloadUrl}`, {
            responseType: 'arraybuffer',
            onDownloadProgress: progressEvent => {
              const percentCompleted = progressEvent.total
                ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                : 0;
              setProgress(percentCompleted);
            },
          })
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'});
            const filename = `${key}_${platform}.zip`;
            saveAs(blob, filename);
            dispatch(clearError());
          })
          .catch((e: Error) => dispatch(setError(e.message)));

        setIsDownloading(false);
        setProgress(0);
      }
    },
    []
  );

  if (!applicationLinks) {
    return <Loading />;
  }

  return (
    <>
      <Navbar showUser={false} />
      <div className='downloads'>
        <div className='wrapper'>
          <h2 className='page-title'>{t('download-title')}</h2>
          <div className='applications'>
            {Object.keys(applicationLinks).map((platform: string) => (
              <div
                key={platform}
                role='application'
                className={`application ${isDownloading ? 'disabled' : ''}`}
                onClick={() =>
                  !isDownloading &&
                  handleDownloadApplication(
                    platform,
                    applicationLinks![platform]
                  )
                }
              >
                <img
                  role='download-application'
                  className={`platform ${platform}`}
                  src={`/images/${platform}.svg`}
                  alt={platform}
                />
                <div className='title'>
                  {`${
                    applicationLinks![platform].split('/')[
                      applicationLinks![platform].split('/').length - 1
                    ][0]
                  }${applicationLinks![platform]
                    .split('/')
                    [applicationLinks![platform].split('/').length - 1].slice(1)
                    .toLowerCase()}`}
                </div>
                <CloudDownloadIcon className='download-icon' />
              </div>
            ))}
          </div>
        </div>
        {isDownloading && (
          <Toast
            additionalClass='general info-toast download-toast'
            message={t('download-progress', {progress})}
          />
        )}
      </div>
    </>
  );
}
