import {API} from 'aws-amplify';
import {put, takeLatest} from 'redux-saga/effects';
import {handleError} from '../../common/utils/errorHandling';
import {setError, clearError} from '../errors/actions';
import {ApiError} from '../errors/types';
import {
  clearLoading,
  clearUploadingImage,
  setBranding,
  setDistribution,
  setLoading,
  setPin,
  setUser,
  uploadImageSuccess,
} from './actions';
import {
  AddClientEmailPayload,
  DeleteBrandingImagePayload,
  Distribution,
  DistributionActionTypes,
  EnableDistributionPayload,
  GetBrandingPayload,
  GetDistributionPayload,
  LoginPayload,
  LoginResponse,
  PinResponse,
  RemoveAllClientEmailsPayload,
  RemoveClientEmailPayload,
  SendAllEmailsPayload,
  SendOneEmailPayload,
  UpdateBrandingColorPayload,
  UpdatePinPayload,
  UploadBrandingImagePayload,
  UploadUserListPayload,
  VerifyPinPayload,
} from './types';

export function* getDistribution({id}: GetDistributionPayload) {
  const response = yield API.get('integrity', `/distribution/${id}`, {})
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* enableOrDisableDistribution({
  payload: {id, enable},
}: EnableDistributionPayload) {
  const response = yield API.put(
    'integrity',
    `/distribution/${id}/${enable ? 'enable' : 'disable'}`,
    {}
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* updatePin({payload: {id, pin}}: UpdatePinPayload) {
  const response = yield API.post('integrity', `/distribution/${id}/pin`, {
    body: {pin},
  })
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* uploadBrandingImage({
  payload: {id, type, file},
}: UploadBrandingImagePayload) {
  yield put(clearUploadingImage());
  yield put(setLoading(type));

  const formData = new FormData();
  formData.append('file', file);
  const response = yield API.post(
    'integrity',
    `/distribution/${id}/images/${type}_IMG`,
    {
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
    yield put(uploadImageSuccess(type, file));
    yield put(clearLoading());
  }
}

export function* updateBrandingColor({
  payload: {id, body},
}: UpdateBrandingColorPayload) {
  const response = yield API.post(
    'integrity',
    `/distribution/${id}/images/colors`,
    {
      body,
    }
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* deleteBrandingImage({
  payload: {id, type},
}: DeleteBrandingImagePayload) {
  const response = yield API.del(
    'integrity',
    `/distribution/${id}/images/${type}_IMG`,
    {}
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* addClientEmail({payload: {id, email}}: AddClientEmailPayload) {
  const response = yield API.post(
    'integrity',
    `/distribution/${id}/email/add`,
    {body: {email}}
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* removeClientEmail({
  payload: {id, email},
}: RemoveClientEmailPayload) {
  const response = yield API.post(
    'integrity',
    `/distribution/${id}/email/delete`,
    {body: {email}}
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* removeAllClientEmails({id}: RemoveAllClientEmailsPayload) {
  const response = yield API.del(
    'integrity',
    `/distribution/${id}/user-list`,
    {}
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* uploadUserList({payload: {file, id}}: UploadUserListPayload) {
  const formData = new FormData();
  formData.append('file', file);
  const response = yield API.post(
    'integrity',
    `/distribution/${id}/user-list`,
    {
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* sendEmailToOne({payload: {id, email}}: SendOneEmailPayload) {
  const response = yield API.post(
    'integrity',
    `/distribution/${id}/email/send`,
    {
      body: {email},
    }
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* sendEmailToAll({id}: SendAllEmailsPayload) {
  const response = yield API.post(
    'integrity',
    `/distribution/${id}/emails/send`,
    {}
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setDistribution(response));
    yield put(clearError());
  }
}

export function* fetchBranding({hash}: GetBrandingPayload) {
  const response = yield API.get(
    'integrity-public',
    `/distribution/${hash}/branding`,
    {}
  )
    .then((response: Distribution) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setBranding(response));
    yield put(clearError());
  }
}

export function* login({data}: LoginPayload) {
  const response = yield API.post(
    'integrity-public',
    '/v2/distribution/login',
    {
      body: data,
    }
  )
    .then((response: LoginResponse) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    yield put(setUser(response));
    yield put(setPin(data.pin));
    yield put(clearError());
  }
}
export function* verifyPin({hash}: VerifyPinPayload) {
  const response = yield API.get(
    'integrity-public',
    `/v2/distribution/${hash}/verify-pin`,
    {}
  )
    .then((response: PinResponse) => response)
    .catch((e: unknown) => handleError(e as ApiError));

  if (response.error) {
    yield put(setError(response.error));
  } else {
    const {pin} = response;
    yield put(setPin(pin));
    yield put(clearError());
  }
}

export function* listenForAllDistributionAction() {
  yield takeLatest(DistributionActionTypes.GET, getDistribution);
  yield takeLatest(DistributionActionTypes.ENABLE, enableOrDisableDistribution);
  yield takeLatest(DistributionActionTypes.PIN, updatePin);
  yield takeLatest(
    DistributionActionTypes.UPLOAD_BRANDING,
    uploadBrandingImage
  );
  yield takeLatest(
    DistributionActionTypes.UPDATE_BRANDING_COLOR,
    updateBrandingColor
  );
  yield takeLatest(
    DistributionActionTypes.DELETE_BRANDING,
    deleteBrandingImage
  );
  yield takeLatest(DistributionActionTypes.ADD_CLIENT_EMAIL, addClientEmail);
  yield takeLatest(
    DistributionActionTypes.REMOVE_CLIENT_EMAIL,
    removeClientEmail
  );
  yield takeLatest(
    DistributionActionTypes.REMOVE_ALL_CLIENT_EMAILS,
    removeAllClientEmails
  );
  yield takeLatest(DistributionActionTypes.UPLOAD_USER_LIST, uploadUserList);
  yield takeLatest(DistributionActionTypes.SEND_ONE_EMAIL, sendEmailToOne);
  yield takeLatest(DistributionActionTypes.SEND_ALL_EMAIL, sendEmailToAll);
  yield takeLatest(DistributionActionTypes.GET_BRANDING, fetchBranding);
  yield takeLatest(DistributionActionTypes.LOGIN, login);
  yield takeLatest(DistributionActionTypes.VERIFY_PIN, verifyPin);
}
