import {ChangeEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ClientDto} from '../../../../../common/types/api';
import {
  addClientEmail,
  uploadUserList,
} from '../../../../../modules/privateDistribution/actions';
import {Distribution} from '../../../../../modules/privateDistribution/types';
import {UserTable} from './userTable';

import './index.scss';

interface IProps {
  client: ClientDto;
  distribution: Distribution;
}

export function UserList({distribution, client}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [email, setEmail] = useState<string>('');

  const handleAddEmail = useCallback(() => {
    if (client.id && email) {
      dispatch(addClientEmail(client.id, email));
      setEmail('');
    }
  }, [client, email]);

  const handleUploadFile = (fileList: FileList | null) => {
    if (client.id && fileList) {
      dispatch(uploadUserList(client.id, fileList[0]));
    }
  };

  return (
    <div className='box section user-list'>
      <div className='head'>
        <h2 className='subhead'>{t('user-list')}</h2>
      </div>
      <div className='content'>
        <div className='form'>
          <div className='form-group'>
            <label>{t('email')}</label>
            <input
              className='input-form email'
              type='email'
              role='email-field'
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              onKeyDown={e => e.key === 'Enter' && email && handleAddEmail()}
            />
          </div>
          <div
            className={`secondary-button add-email ${!email ? 'disabled' : ''}`}
            onClick={() => email && handleAddEmail()}
            role='add-user-button'
          >
            {t('add')}
          </div>
          <div
            className='secondary-button upload-mail-list'
            onClick={() => document.getElementById('userList')?.click()}
            role='upload-file-button'
          >
            {t('upload-mail-list')}
          </div>
          <div className='form-group file-group file'>
            <input
              role='upload-file'
              className='input-form'
              id='userList'
              type='file'
              multiple={false}
              accept='.csv'
              onChange={event => handleUploadFile(event.target.files)}
            />
          </div>
        </div>
        <UserTable client={client} users={distribution.users} />
      </div>
    </div>
  );
}
