import {Amplify, Auth} from 'aws-amplify';
import axios from 'axios';
import {put, takeEvery} from 'redux-saga/effects';
import {setConfig} from './actions';
import {ConfigActionTypes} from './types';

export async function currentUserToken() {
  return Auth.currentAuthenticatedUser().then(user => {
    return user.signInUserSession.accessToken.jwtToken;
  });
}

export function* getConfig() {
  const config = yield axios
    .get(`${process.env.REACT_APP_REDIRECT_URL ?? ''}/api/config/web`)
    .then(response => ({
      Auth: {
        mandatorySignIn: true,
        region: response.data.sportradar.region,
        userPoolId: response.data.sportradar.userPoolId,
        userPoolWebClientId: response.data.sportradar.clientId,
        oauth: {
          domain: response.data.sportradar.webDomain.replace(
            /^https?:\/\//,
            ''
          ),
          scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `${
            process.env.REACT_APP_LOGIN_URL ?? window.location.origin
          }/login`,
          redirectSignOut: `${
            process.env.REACT_APP_LOGIN_URL ?? window.location.origin
          }/logout`,
          responseType: 'code',
        },
      },
      API: {
        endpoints: [
          {
            name: 'integrity',
            endpoint: `${process.env.REACT_APP_REDIRECT_URL ?? ''}/api`,
            region: response.data.sportradar.region,
            custom_header: async (): Promise<{
              Authorization: string;
            }> => {
              return {
                Authorization: `Bearer ${await currentUserToken()}`,
              };
            },
          },
          {
            name: 'integrity-public',
            endpoint: `${process.env.REACT_APP_REDIRECT_URL ?? ''}/api`,
            region: response.data.sportradar.region,
          },
        ],
      },
    }));

  Amplify.configure(config);

  yield put(setConfig(config));
}

export function* listenForAllConfigAction() {
  yield takeEvery(ConfigActionTypes.GET, getConfig);
}
