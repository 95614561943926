import {ChevronLeftIcon, ChevronRightIcon} from '@betting/oui-icons';
import ReactPaginate from 'react-paginate';
import './index.scss';

interface IProps {
  numberOfPages: number;
  pageNumber: number;
  handlePageChange: (selectedItem: {selected: number}) => void;
}

export function Pagination({
  numberOfPages,
  pageNumber,
  handlePageChange,
}: IProps): JSX.Element {
  return (
    <div className='pagination-container' role='pagination'>
      <ReactPaginate
        pageCount={numberOfPages}
        onPageChange={handlePageChange}
        activeClassName='active'
        pageClassName='page-number'
        containerClassName='pagination'
        breakClassName='break-me'
        marginPagesDisplayed={3}
        previousLabel={
          <div hidden={pageNumber === 0}>
            <ChevronLeftIcon className='pagination-icon' />
          </div>
        }
        nextLabel={
          <div hidden={pageNumber === numberOfPages - 1}>
            <ChevronRightIcon className='pagination-icon' />
          </div>
        }
        forcePage={pageNumber}
      />
    </div>
  );
}
