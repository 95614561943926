import {CognitoUser} from '../../common/types/cognitoUser';
import {
  FETCH_USER_FAILURE,
  FETCH_USER_INIT,
  FETCH_USER_SUCCESS,
  RESET_USER,
} from './types';

export function fetchUserInit() {
  return {
    type: FETCH_USER_INIT,
  };
}

export function fetchUserSuccess(data: CognitoUser) {
  return {
    type: FETCH_USER_SUCCESS,
    payload: data,
  };
}

export function fetchUserFailure() {
  return {
    type: FETCH_USER_FAILURE,
  };
}

export function resetUser() {
  return {
    type: RESET_USER,
  };
}
