import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ClientDto} from '../../../../../common/types/api';
import {updatePin} from '../../../../../modules/privateDistribution/actions';
import {Distribution} from '../../../../../modules/privateDistribution/types';
import {QRCodeSVG} from 'qrcode.react';

import './index.scss';

interface IProps {
  client: ClientDto;
  distribution?: Distribution;
}

export function Details({distribution, client}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {register, getValues} = useForm({mode: 'onChange'});

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleOnChange = useCallback(
    (pin: string) => {
      if (Number(pin) !== distribution?.pin) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    },
    [distribution]
  );

  const handleUpdatePin = useCallback(() => {
    if (client.id && !isDisabled) {
      dispatch(updatePin(client.id, getValues().pin));
      setIsDisabled(true);
    }
  }, [getValues, client, isDisabled]);

  const handleCopyUrl = useCallback(() => {
    if (distribution) {
      navigator.clipboard.writeText(distribution.distributionUrl);
    }
  }, [distribution]);

  const handleCopyFilename = useCallback(() => {
    if (distribution) {
      navigator.clipboard.writeText(distribution.distributionFileName);
    }
  }, [distribution]);

  return (
    <div className='box section'>
      <div className='head'>
        <h2 className='subhead' role='subhead'>
          {t('private-distribution')}
        </h2>
      </div>
      <form className='details-content'>
        <div className='left'>
          <div className='form'>
            <div className='form-group'>
              <label>{t('client-url')}</label>
              <input
                className='input-form url'
                id='url'
                disabled
                defaultValue={distribution?.distributionUrl}
              />
            </div>
            <div
              className='secondary-button'
              onClick={handleCopyUrl}
              role='copy-to-clipboard-button'
            >
              {t('copy-url')}
            </div>
          </div>
          <div className='form'>
            <div className='form-group'>
              <label>{t('file-name')}</label>
              <input
                className='input-form url'
                id='filename'
                disabled
                defaultValue={distribution?.distributionFileName}
              />
            </div>
            <div
              className='secondary-button'
              onClick={handleCopyFilename}
              role='copy-to-clipboard-button-2'
            >
              {t('copy-url')}
            </div>
          </div>
          <div className='form'>
            <div className='form-group'>
              <label>{t('pin')}</label>
              <input
                className='input-form pin'
                id='pin'
                role='pin'
                {...register('pin')}
                defaultValue={distribution?.pin}
                onChange={event => handleOnChange(event.target.value)}
              />
            </div>
            <div
              className={`secondary-button ${isDisabled ? 'disabled' : ''}`}
              onClick={handleUpdatePin}
              role='save-details-button'
            >
              {t('update')}
            </div>
          </div>
        </div>
        <div className='right'>
          {distribution && (
            <div className='qr-code'>
              <QRCodeSVG
                value={distribution?.distributionFileName}
                style={{width: '100%', height: 'auto'}}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
