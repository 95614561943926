import {Fragment, useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ClientDto} from '../../../../../common/types/api';
import {
  clearWasImageUploaded,
  updateBrandingColor,
} from '../../../../../modules/privateDistribution/actions';
import {Distribution} from '../../../../../modules/privateDistribution/types';
import {BrandingColors} from './colors';
import {BrandingElement} from './element';

import {Toast} from '../../../../../common/components/toast';
import {selectIfImageWasUploaded} from '../../../../../modules/privateDistribution/selectors';
import './index.scss';

interface IProps {
  client: ClientDto;
  distribution: Distribution;
}

const brandingOptions = ['LOGO', 'BACKGROUND', 'ICON'];

export function Branding({client, distribution}: IProps) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {handleSubmit, register} = useForm();

  const wasImageUploaded = useSelector(selectIfImageWasUploaded);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleUpdateBrandingColor = useCallback(
    data => {
      if (client.id) {
        dispatch(updateBrandingColor(client.id, data));
        setIsDisabled(true);
      }
    },
    [client]
  );

  return (
    <div className='box section'>
      <div className='head'>
        <h2 className='subhead' role='subhead'>
          {t('branding')}
        </h2>
      </div>
      <div className='branding-content'>
        {brandingOptions.map(option => (
          <Fragment key={`${option}-element`}>
            <BrandingElement
              type={option}
              client={client}
              distribution={distribution}
            />
          </Fragment>
        ))}
      </div>
      <div className='branding-content'>
        <form>
          <div className='form'>
            {brandingOptions.map(option => (
              <Fragment key={`${option}-color`}>
                <BrandingColors
                  distribution={distribution}
                  type={option}
                  register={register}
                  setDisabled={setIsDisabled}
                />
              </Fragment>
            ))}
          </div>
          <div className='controls'>
            <button
              role='save-colors-button'
              className='primary-button'
              disabled={isDisabled}
              onClick={handleSubmit(handleUpdateBrandingColor)}
            >
              {t('save')}
            </button>
          </div>
        </form>
      </div>
      {wasImageUploaded && (
        <Toast
          message={t('image-uploaded-success')}
          additionalClass={'info-toast'}
          close={true}
          onCloseAction={() => dispatch(clearWasImageUploaded())}
        />
      )}
    </div>
  );
}
