import {Upload2Icon} from '@betting/oui-icons';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Toast} from '../../../../common/components/toast';
import {ClientDto} from '../../../../common/types/api';
import {uploadPublicKey} from '../../../../modules/clients/actions';
import {selectConfig} from '../../../../modules/config/selectors';

import './index.scss';

interface IProps {
  client: ClientDto;
}

export function ClientPublicKey({client}: IProps): JSX.Element {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);

  const [file, setFile] = useState<File>();

  const handleUploadChange = useCallback((data: FileList | null) => {
    if (data) {
      setFile(data[0]);
    }
  }, []);

  const handleUploadPublicKey = () => {
    if (client.id && file && config) {
      dispatch(
        uploadPublicKey(file, client.id, config.API.endpoints[0].endpoint)
      );
      setFile(undefined);
      (document.getElementById('publicKey') as HTMLInputElement)!.value = '';
    }
  };

  return (
    <div className='box section public-key'>
      <div className='head'>
        <h2 className='subhead'>{t('client-public-key')}</h2>
      </div>
      <div className='body'>
        <div className='subsection left'>
          <form>
            <div className='form'>
              <div className='form-group full'>
                <label>{t('existing-key')}</label>
                <input
                  className='input-form'
                  role='public-key'
                  defaultValue={client.publicKey}
                  disabled
                />
              </div>
            </div>
          </form>
        </div>
        <div className='subsection right'>
          <form>
            <div className='form'>
              <div className='form-group full file-group'>
                <label>{t('browse-key')}</label>
                <input
                  type='file'
                  id='publicKey'
                  className='input-form'
                  accept='.der'
                  onChange={event => handleUploadChange(event.target.files)}
                />
                <div
                  role='select-file-button'
                  className='file-wrapper input-form'
                  onClick={() => document.getElementById('publicKey')?.click()}
                >
                  {file ? file.name : t('public-key-placeholder')}
                  <div className='upload-icon'>
                    <Upload2Icon />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='controls'>
        {!client.publicKey && (
          <Toast
            message={t('public-key-warning')}
            additionalClass='info-toast no-file-toast'
          />
        )}

        <button
          role='upload-public-key-button'
          className='primary-button upload-file'
          onClick={handleUploadPublicKey}
          disabled={!file}
        >
          {t('save')}
        </button>
      </div>
    </div>
  );
}
