import {PlusAddIcon, TrashDeleteIcon, Upload2Icon} from '@betting/oui-icons';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ClientDto, ClientFileDto, Language} from '../../../../common/types/api';
import {
  deleteClientFile,
  fetchClientFiles,
  setClientFiles,
  uploadClientFile,
} from '../../../../modules/clients/actions';
import {selectClientFiles} from '../../../../modules/clients/selectors';
import {selectConfig} from '../../../../modules/config/selectors';
import {fetchLanguages} from '../../../../modules/languages/actions';
import {selectLanguages} from '../../../../modules/languages/selectors';
import Modal from 'react-modal';
import {modalStyles} from '../../../../config/modal';
import {DeleteModal} from '../../../../common/components/deleteModal';
import {DownloadProgress} from '../../../downloadMobile/downloadOnMobile/download/downloadProgress';
import {Progress} from '../../../../modules/privateDistribution/types';

import './index.scss';

interface IProps {
  client: ClientDto;
}

export function ClientFiles({client}: IProps) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {isValid},
  } = useForm({
    mode: 'all',
  });
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const languages = useSelector(selectLanguages);
  const files = useSelector(selectClientFiles);

  const [file, setFile] = useState<File>();
  const [disabled, setIsDisabled] = useState<boolean>(true);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<ClientFileDto>();
  const [uploadProgress, setUploadProgress] = useState<Progress>();

  useEffect(() => {
    if (languages && !languages.length) {
      dispatch(fetchLanguages());
    }

    if (client.id && files?.length === 0) {
      dispatch(fetchClientFiles(client.id));
    }
  }, []);

  // Clear files on unmount
  useEffect(
    () => () => {
      dispatch(setClientFiles([]));
    },
    []
  );

  useEffect(() => {
    setIsDisabled(!isValid || file === undefined);
  }, [isValid, file]);

  const handleUploadChange = useCallback(
    (data: FileList | null) => {
      if (data) {
        setFile(data[0]);
      }
    },
    [setFile]
  );

  const handleUploadFile = useCallback(
    (data: ClientFileDto) => {
      data.file = file;
      if (client.id && config && !disabled) {
        dispatch(
          uploadClientFile(
            data,
            client.id,
            config.API.endpoints[0].endpoint,
            progressEvent =>
              setUploadProgress({
                total: progressEvent.total ?? 0,
                downloaded: progressEvent.loaded,
                percentage: progressEvent.total
                  ? Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    )
                  : 0,
              })
          )
        );
      }
    },
    [file, disabled]
  );

  const handleDeleteClientFile = useCallback(() => {
    if (client.id && fileToBeDeleted) {
      dispatch(deleteClientFile(fileToBeDeleted, client.id));
      setFileToBeDeleted(undefined);
    }
  }, [client, fileToBeDeleted]);

  useEffect(() => {
    setFile(undefined);
    setValue('title', undefined);
    setValue('language', 'en');
    setIsDisabled(true);
    setUploadProgress(undefined);
  }, [files]);

  if (languages.length === 0) {
    return <></>;
  }

  return (
    <div className='box section client-files'>
      <div className='content'>
        <div className='head'>
          <h2 className='subhead'>{t('client-files')}</h2>
        </div>
        <form onSubmit={event => event.preventDefault()}>
          <div className='form'>
            <div className='subsection'>
              <div className='form-group language'>
                <label>{t('language')}</label>
                <select
                  className='input-form'
                  id='language'
                  {...register('language', {
                    required: true,
                  })}
                  defaultValue='en'
                  autoComplete='on'
                >
                  {languages.length !== 0 &&
                    languages.map((language: Language) => (
                      <option key={language.code} value={language.code}>
                        {language.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className='form-group title file-group'>
                <label>{t('title')}</label>
                <input
                  className='input-form'
                  id='title'
                  placeholder={t('title')}
                  {...register('title', {
                    required: true,
                  })}
                />
              </div>
            </div>
            <div className='subsection'>
              <div className='form-group file-group file'>
                <label>{t('file')}</label>
                <input
                  role='upload-file'
                  className='input-form'
                  id='file'
                  type='file'
                  accept='application/pdf'
                  onChange={event => handleUploadChange(event.target.files)}
                  // setting the input value to empty string ensures the onChange hook to trigger when uploading the same file twice
                  value=''
                />
                <div
                  className='file-wrapper input-form'
                  tabIndex={0}
                  onClick={() => document.getElementById('file')?.click()}
                  onKeyDown={e =>
                    e.key === 'Enter' &&
                    document.getElementById('file')?.click()
                  }
                  role='filename'
                >
                  {file ? file.name : t('file-placeholder')}
                  <div className='upload-icon'>
                    <Upload2Icon />
                  </div>
                </div>
              </div>
              <div
                className={`secondary-button ${disabled ? 'disabled' : ''}`}
                tabIndex={0}
                onClick={handleSubmit(handleUploadFile)}
                onKeyDown={e =>
                  e.key === 'Enter' &&
                  !disabled &&
                  handleUploadFile(getValues())
                }
              >
                <PlusAddIcon />
              </div>
            </div>
            {uploadProgress && (
              <div className='right'>
                <DownloadProgress progress={uploadProgress} />
              </div>
            )}
          </div>
        </form>
      </div>
      <div className='files'>
        {files.map((listFile, index) => (
          <React.Fragment key={`${listFile.fileName}-${index}`}>
            <hr />
            <div className='client-file' role='file-row'>
              <div className='subsection'>
                <div className='language'>{listFile.language}</div>
                <div className='title'>{listFile.title}</div>
              </div>
              <div className='subsection'>
                <div className='file'>{listFile.fileName}</div>
                <TrashDeleteIcon
                  className='remove-icon'
                  tabIndex={0}
                  onClick={() => setFileToBeDeleted(listFile)}
                  onKeyDown={e =>
                    e.key === 'Enter' && setFileToBeDeleted(listFile)
                  }
                />
              </div>
            </div>
          </React.Fragment>
        ))}
        <Modal
          isOpen={fileToBeDeleted !== undefined}
          style={modalStyles}
          onRequestClose={() => setFileToBeDeleted(undefined)}
          ariaHideApp={false}
        >
          <div role='delete-modal'>
            <DeleteModal
              message={t('remove-client-file-message')}
              deleteAction={() => handleDeleteClientFile()}
              cancelAction={() => setFileToBeDeleted(undefined)}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
