import {getSize} from '../../../../../common/utils/getSize';
import {Progress} from '../../../../../modules/privateDistribution/types';
import {useTranslation} from 'react-i18next';

import './index.scss';

interface IProps {
  progress: Progress;
}

export function DownloadProgress({progress}: IProps) {
  const {t} = useTranslation();

  return (
    <div className='progress'>
      <div className='wrapper'>
        <div
          style={{
            width: `${progress.percentage}%`,
          }}
          className='bar'
        ></div>
      </div>
      {progress.percentage !== 100 ? (
        <p>{`${progress.percentage}% - ${getSize(
          progress.downloaded
        )} / ${getSize(progress.total)}`}</p>
      ) : (
        <p>{t('download-completed')}</p>
      )}
    </div>
  );
}
