import {Close2Icon, PlusAddIcon} from '@betting/oui-icons';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ClientDto} from '../../../../common/types/api';
import {
  addClientEmail,
  removeClientEmail,
} from '../../../../modules/clients/actions';
import Modal from 'react-modal';
import {DeleteModal} from '../../../../common/components/deleteModal';
import {modalStyles} from '../../../../config/modal';

import './index.scss';

interface IProps {
  client: ClientDto;
}

export function ReportingEmails({client}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {register, handleSubmit, setValue, getValues} = useForm({mode: 'all'});

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleAddingEmail = useCallback(
    data => {
      if (client.id) {
        dispatch(addClientEmail(data.email, client.id));
        setValue('email', undefined);
      }
    },
    [client]
  );

  const handleRemoveEmail = useCallback((email: string) => {
    if (client.id) {
      dispatch(removeClientEmail(email, client.id));
      setIsModalOpen(false);
    }
  }, []);

  return (
    <div className='section box reporting-emails'>
      <div className='head'>
        <h2 className='subhead'>{t('reporting-emails')}</h2>
      </div>
      <div className='add-email'>
        <form onSubmit={handleSubmit(handleAddingEmail)}>
          <div className='form'>
            <div className='inline-form-group'>
              <input
                role='add-email-input'
                className='input-form'
                {...register('email', {
                  required: true,
                })}
                placeholder={t('email')}
              />
              <div
                role='add-email-button'
                className='secondary-button'
                onClick={handleSubmit(handleAddingEmail)}
                onKeyDown={e =>
                  e.key === 'Enter' && handleAddingEmail(getValues())
                }
                tabIndex={0}
              >
                <PlusAddIcon />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='client-emails'>
        {client.emails?.map((email: string) => (
          <div className='email' key={email} role='reporting-email'>
            {email}
            <div
              className='remove-email'
              role='remove-email-button'
              onClick={() => setIsModalOpen(true)}
            >
              <Close2Icon />
            </div>
            <Modal
              isOpen={isModalOpen}
              style={modalStyles}
              onRequestClose={() => setIsModalOpen(false)}
              ariaHideApp={false}
            >
              <DeleteModal
                message={t('remove-email-message')}
                deleteAction={() => handleRemoveEmail(email)}
                cancelAction={() => setIsModalOpen(false)}
              />
            </Modal>
          </div>
        ))}
      </div>
    </div>
  );
}
