import {t} from 'i18next';
import {isEmpty, isEqual} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import Switch from 'react-switch';
import {
  ClientDto,
  ReportSettingsRequestBody,
} from '../../../../common/types/api';
import {switchProps} from '../../../../config/switch';
import {updateClientReporting} from '../../../../modules/clients/actions';

import './index.scss';

interface IProps {
  client: ClientDto;
}
export function Reporting({client}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const {
    setValue,
    getValues,
    register,
    handleSubmit,
    watch,
    formState: {isDirty, isValid, errors},
  } = useForm({mode: 'onChange'});

  const [reportIdNumberType, setReportIdNumberType] = useState<
    'ID' | 'REPORT_ID' | undefined
  >(client.reportIdNumberType);
  const [enabledEmails, setEnabledEmails] = useState<boolean>(
    client.enabled ?? false
  );
  const [disabled, setIsDisabled] = useState<boolean>(!isValid || !isDirty);
  const [clientReporting, setClientReporting] =
    useState<ReportSettingsRequestBody>();
  const watchAllFields = watch();

  useEffect(() => {
    setClientReporting({
      reportIdNumberType: client.reportIdNumberType,
      inAppDecryptionEnabled: client.inAppDecryptionEnabled,
      reportMaxSizeInMegaBytes: client.reportMaxSizeInMegaBytes
        ? client.reportMaxSizeInMegaBytes.toString()
        : undefined,
      enabled: client.enabled,
      subject: client.subject,
      text: client.text,
    });
    setValue('subject', client.subject);
    setValue('text', client.text);
    setValue(
      'enabled',
      clientReporting ? clientReporting.enabled : client.enabled ?? false
    );
    setEnabledEmails(
      clientReporting
        ? clientReporting.enabled === true
        : client.enabled ?? false
    );
    setValue('reportIdNumberType', client.reportIdNumberType);
  }, [client]);

  useEffect(() => {
    disableButton();
  }, [watchAllFields]);

  const disableButton = () => {
    const values = getValues();

    Object.keys(values).map(key => {
      if (values[key] === '') {
        values[key] = undefined;
      }
    });

    setIsDisabled(
      !isValid || isEqual(clientReporting, values) || !isEmpty(errors)
    );
  };

  const handleEditReporting = useCallback(
    (data: ReportSettingsRequestBody) => {
      if (client.id) {
        data.enabled = enabledEmails;
        dispatch(
          updateClientReporting(
            {
              ...data,
              reportMaxSizeInMegaBytes:
                data.reportMaxSizeInMegaBytes &&
                typeof data.reportMaxSizeInMegaBytes === 'string'
                  ? parseInt(data.reportMaxSizeInMegaBytes)
                  : null,
            },
            client.id
          )
        );
        setIsDisabled(true);
        setClientReporting(data);
      }
    },
    [enabledEmails, client]
  );

  const handleChangeEnableEmails = useCallback((value: boolean) => {
    const {subject, text} = getValues();
    setValue('enabled', value);
    !subject && setValue('subject', client.subject);
    !text && setValue('text', client.text);
    setEnabledEmails(value);
  }, []);

  return (
    <div className='box section reporting'>
      <div className='subsection left'>
        <h2 className='subhead'>{t('reporting')}</h2>
        <form onSubmit={handleSubmit(handleEditReporting)}>
          <div className='form'>
            <div className='form-group full'>
              <label>{t('report-id-number-type')}</label>
              <div className='switch-group'>
                <label
                  tabIndex={0}
                  className={`switch-button ${
                    reportIdNumberType === 'ID' ? 'active' : ''
                  }`}
                  onClick={() => setReportIdNumberType('ID')}
                  onKeyDown={e =>
                    e.key === 'Enter' && setReportIdNumberType('ID')
                  }
                >
                  <input
                    type='radio'
                    {...register('reportIdNumberType')}
                    name='reportIdNumberType'
                    value={'ID'}
                    defaultChecked={client.reportIdNumberType === 'ID'}
                  />
                  <span>{t('incremental-report-id')}</span>
                </label>
                <label
                  role='report-id-button'
                  tabIndex={0}
                  className={`switch-button ${
                    reportIdNumberType === 'REPORT_ID' ? 'active' : ''
                  }`}
                  onClick={() => setReportIdNumberType('REPORT_ID')}
                  onKeyDown={e =>
                    e.key === 'Enter' && setReportIdNumberType('REPORT_ID')
                  }
                >
                  <input
                    type='radio'
                    {...register('reportIdNumberType')}
                    name='reportIdNumberType'
                    value={'REPORT_ID'}
                  />
                  <span>{t('generated-report-id')}</span>
                </label>
              </div>
            </div>
            <div className='inline-form-group full'>
              <div className='decryption'>
                <input
                  type='checkbox'
                  id='inAppDecryptionEnabled'
                  {...register('inAppDecryptionEnabled', {})}
                  defaultChecked={client.inAppDecryptionEnabled}
                />
                <label className='checkbox-label'>
                  {t('decryption-enabled')}
                </label>
              </div>
              <div className='report-size'>
                <label>{t('max-report-size')}</label>
                <input
                  type='number'
                  className='input-form small'
                  id='reportMaxSizeInMegaBytes'
                  {...register('reportMaxSizeInMegaBytes')}
                  defaultValue={client.reportMaxSizeInMegaBytes}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='subsection right'>
        <h2 className='subhead'>{t('custom-email')}</h2>
        <form onSubmit={handleSubmit(handleEditReporting)}>
          <div className='form'>
            <div className='toogle-button email-toogle'>
              <Switch
                role='enable-email-button'
                checked={enabledEmails}
                onChange={(value: boolean) => handleChangeEnableEmails(value)}
                {...switchProps}
              />
            </div>
            <div className='form-group full'>
              <label>{t('email-subject')}</label>
              <input
                role='email-subject-input'
                className={`input-form ${
                  enabledEmails && errors.subject ? 'error' : ''
                }`}
                id='subject'
                {...register('subject', {
                  required: enabledEmails && 'error',
                })}
                defaultValue={client.subject}
                disabled={!enabledEmails}
              />
            </div>
            <div className='form-group full'>
              <label>{t('email-text')}</label>
              <textarea
                role='email-text-input'
                className={`input-form email-text ${
                  enabledEmails && errors.text ? 'error' : ''
                }`}
                id='text'
                {...register('text', {
                  required: enabledEmails && 'error',
                })}
                defaultValue={client.text}
                disabled={!enabledEmails}
              />
            </div>
          </div>
          <div className='controls'>
            <button
              role='save-reporting-button'
              className='primary-button'
              onClick={handleSubmit(handleEditReporting)}
              disabled={disabled}
            >
              {t('save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
