import './index.scss';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectPin,
  selectUser,
} from '../../../modules/privateDistribution/selectors';
import {Branding} from '../../../modules/privateDistribution/types';
import {Download} from './download';
import {Login} from './login';
import {useEffect} from 'react';
import {useParams} from 'react-router';
import {verifyPin} from '../../../modules/privateDistribution/actions';

interface IProps {
  branding?: Branding;
}

export function DownloadOnMobile({branding}: IProps): JSX.Element {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {hash} = useParams();

  const user = useSelector(selectUser);
  const pin = useSelector(selectPin);

  useEffect(() => {
    if (hash) {
      dispatch(verifyPin(hash));
    }
  }, [hash]);

  if (pin === undefined) {
    return <></>;
  }

  return (
    <div className='mobile wrapper' role='download-on-mobile-page'>
      <div className='logo-header' style={{background: branding?.LOGO?.color}}>
        <img src={branding?.LOGO?.url} />
      </div>
      <div className='content'>
        <div className='header'>
          <img src={branding?.ICON?.url} className='icon' />
          <div className='title'>
            <strong>{t('mobile-download-title')}</strong>
            <span>{t('mobile-download-subtitle')}</span>
          </div>
        </div>
        {!user && pin ? <Login /> : <Download />}
      </div>
    </div>
  );
}
