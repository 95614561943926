import {differenceInMinutes, format, isToday, isYesterday} from 'date-fns';

export function parseDate(dateNumber: number): string {
  const date = new Date(dateNumber);

  return `${format(date, 'dd.MM.yyyy')} @ ${format(date, 'HH:mm:ss')}`;
}

export function lastModified(dateNumber: number): string {
  const date = new Date(dateNumber);

  if (isToday(date)) {
    // If difference is less than 5 minutes it is treated like now.
    if (Math.abs(differenceInMinutes(new Date(), date)) < 5) {
      return `Now @ ${format(date, 'HH:mm:ss')}`;
    } else {
      return `Today @ ${format(date, 'HH:mm:ss')}`;
    }
  }

  if (isYesterday(date)) {
    return `Yesterday @ ${format(date, 'HH:mm:ss')}`;
  }

  return `${format(date, 'dd.MM.yyyy')} @ ${format(date, 'HH:mm:ss')}`;
}
