import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Navbar} from './common/components/navbar';
import {ProtectedRoute} from './common/components/protectedRoute';
import {protectedRoutes, routes} from './config/routes';
import {getConfig} from './modules/config/actions';
import {selectConfig} from './modules/config/selectors';
import {clearError} from './modules/errors/actions';
import {selectError} from './modules/errors/selectors';
import {Toast} from './common/components/toast';

import './App.scss';

interface IProps {
  showUser?: boolean;
}

function App({showUser = true}: IProps) {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const error = useSelector(selectError);

  useEffect(() => {
    dispatch(getConfig());
  }, []);

  const handleOnCloseError = useCallback(() => {
    dispatch(clearError());
  }, [dispatch]);

  if (!config) {
    return <></>;
  }

  return (
    <div className='App' role='app'>
      <BrowserRouter>
        <Routes>
          {routes.map(route => (
            <Route
              key={route.key}
              path={route.path}
              element={<route.component />}
            />
          ))}
          <Route element={<ProtectedRoute />}>
            {protectedRoutes.map(route => (
              <Route
                key={route.key}
                path={route.path}
                element={
                  <>
                    <Navbar showUser={showUser} />
                    <div className='content-box'>
                      <route.component />
                    </div>
                  </>
                }
              />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
      {error && (
        <div role='error'>
          <Toast
            message={error}
            close={true}
            onCloseAction={handleOnCloseError}
            additionalClass='error-toast general'
          />
        </div>
      )}
    </div>
  );
}

export default App;
