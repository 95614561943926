import {CloseXIcon, WarningCircleIcon} from '@betting/oui-icons';

import './index.scss';

interface IProps {
  message?: string;
  close?: boolean;
  onCloseAction?: () => void;
  additionalClass?: string;
}

export function Toast({
  message,
  close = false,
  onCloseAction = () => {},
  additionalClass = '',
}: IProps): JSX.Element {
  return (
    <div className={`toast ${additionalClass ?? ''}`}>
      <WarningCircleIcon />
      <div className='message'>{message}</div>
      {close && <CloseXIcon className='close-toast' onClick={onCloseAction} />}
    </div>
  );
}
