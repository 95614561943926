import {
  ApplicationLinksDto,
  ClientDto,
  ClientFileDto,
} from '../../common/types/api';
import {
  addClientEmail,
  createClient,
  createSoftwareKey,
  deleteClient,
  deleteClientFile,
  downloadApplication,
  fetchClientFiles,
  getApplicationLinks,
  removeClientEmail,
  resetSoftwareKey,
  sendKeyToClient,
  updateClientDetails,
  updateClientReporting,
  uploadClientFile,
  uploadPublicKey,
} from './actions';

export enum ClientsActionTypes {
  GET = '@clients/GET',
  SET = '@clients/SET',
  CREATE = '@clients/CREATE',
  UPDATE = '@clients/UPDATE',
  UPDATE_DETAILS = '@clients/UPDATE_DETAILS',
  UPDATE_REPORTING = '@clients/UPDATE_REPORTING',
  ADD_EMAIL = '@clients/ADD_EMAIL',
  REMOVE_EMAIL = '@clients/REMOVE_EMAIL',
  CREATE_KEY = '@clients/CREATE_KEY',
  RESET_KEY = '@clients/RESET_KEY',
  UPLOAD_PUBLIC_KEY = '@clients/UPLOAD_PUBLIC_KEY',
  UPLOAD_CLIENT_FILE = '@clients/UPLOAD_CLIENT_FILE',
  FETCH_CLIENT_FILES = '@clients/FETCH_CLIENT_FILES',
  SET_CLIENT_FILES = '@clients/SET_CLIENT_FILES',
  DELETE_CLIENT_FILE = '@clients/DELETE_CLIENT_FILE',
  REMOVE_CLIENT_FILE = '@clients/REMOVE_CLIENT_FILE',
  SET_LOADING = '@clients/SET_LOADING',
  SEND_KEY_TO_CLIENT = '@clients/SEND_KEY_TO_CLIENT',
  SET_MESSAGE = '@clients/SET_MESSAGE',
  CLEAR_MESSAGE = '@clients/CLEAR_MESSAGE',
  GET_APPLICATION_LINKS = '@clients/GET_APPLICATION_LINKS',
  SET_APPLICATION_LINKS = '@clients/SET_APPLICATION_LINKS',
  DOWNLOAD_APPLICATION = '@clients/DOWLNOAD_APPLICATION',
  SET_PAGE = '@clients/SET_PAGE',
  DELETE_CLIENT = '@clients/DELETE_CLIENT',
  REMOVE_CLIENT = '@clients/REMOVE_CLIENT',
}

export interface ClientsState {
  clients: ClientDto[];
  files: ClientFileDto[];
  loading: boolean;
  message?: string;
  links?: ApplicationLinksDto;
  page: number;
}

export type CreateClientPayload = ReturnType<typeof createClient>;
export type UpdateClientDetailsPayload = ReturnType<typeof updateClientDetails>;
export type UpdateClientReportingPayload = ReturnType<
  typeof updateClientReporting
>;
export type AddClientEmailPayload = ReturnType<typeof addClientEmail>;
export type RemoveClientEmailPayload = ReturnType<typeof removeClientEmail>;
export type CreateSoftwareKeyPayload = ReturnType<typeof createSoftwareKey>;
export type ResetSoftwareKeyPayload = ReturnType<typeof resetSoftwareKey>;
export type UploadPublicKeyPayload = ReturnType<typeof uploadPublicKey>;
export type UploadClientFilePayload = ReturnType<typeof uploadClientFile>;
export type GetClientFilesPayload = ReturnType<typeof fetchClientFiles>;
export type DeleteClientFilePayload = ReturnType<typeof deleteClientFile>;
export type SendKeyToClientPayload = ReturnType<typeof sendKeyToClient>;
export type GetApplicationLinksPayload = ReturnType<typeof getApplicationLinks>;
export type DownloadApplicationPayload = ReturnType<typeof downloadApplication>;
export type DeleteClientPayload = ReturnType<typeof deleteClient>;

export interface ProgressEvent {
  loaded: number;
  total: number;
}
