import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ClientDto, DetailsRequestBody} from '../../../../common/types/api';
import {toUpperCase} from '../../../../common/utils/upperCase';
import {updateClientDetails} from '../../../../modules/clients/actions';
import {isEqual} from 'lodash';
import {Toast} from '../../../../common/components/toast';

interface IProps {
  client: ClientDto;
}

export function Details({client}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {
    getValues,
    register,
    handleSubmit,
    formState: {isDirty, isValid, errors},
  } = useForm({mode: 'all'});

  const [disabled, setIsDisabled] = useState<boolean>(!isValid || !isDirty);

  const clientDetails = {
    abbrevation: client.abbrevation,
    name: client.name,
    androidFingerprint: client.androidFingerprint,
    iOSBundleId: client.iOSBundleId,
  };

  const handleEditBasicInfo = useCallback(
    (details: DetailsRequestBody) => {
      if (client.id) {
        dispatch(
          updateClientDetails(
            {
              ...details,
              androidFingerprint:
                details.androidFingerprint !== ''
                  ? details.androidFingerprint
                  : undefined,
              iOSBundleId:
                details.iOSBundleId !== '' ? details.iOSBundleId : undefined,
            },
            client.id
          )
        );
        setIsDisabled(true);
      }
    },
    [client]
  );

  const handleOnChange = () => {
    setIsDisabled(!isValid || isEqual(clientDetails, getValues()));
  };

  return (
    <div className='box section'>
      <div className='head'>
        <h2 className='subhead'>Basic information</h2>
        <h2 className='subhead id'>ID: {client.id}</h2>
      </div>
      <form onChange={handleOnChange}>
        <div className='form'>
          <div className='form-group abbrevation'>
            <label>{t('abbrevation')}</label>
            <input
              role='abbr-input'
              className='input-form'
              id='abbreviation'
              {...register('abbrevation', {
                required: true,
                maxLength: {value: 4, message: t('to-long-abbr')},
              })}
              defaultValue={client.abbrevation}
              onInput={toUpperCase}
            />
          </div>
          <div className='form-group name'>
            <label>{t('full-name')}</label>
            <input
              role='name-input'
              className='input-form'
              id='name'
              {...register('name', {
                required: true,
              })}
              defaultValue={client.name}
            />
          </div>
          <div className='form-group half left'>
            <label>{t('android-fingerprint')}</label>
            <input
              className='input-form'
              id='androidFingerprint'
              {...register('androidFingerprint', {
                required: false,
              })}
              defaultValue={client.androidFingerprint}
            />
          </div>
          <div className='form-group half right'>
            <label>{t('ios-bundle')}</label>
            <input
              className='input-form'
              id='iOSBundleId'
              {...register('iOSBundleId', {
                required: false,
              })}
              defaultValue={client.iOSBundleId}
            />
          </div>
        </div>
        {errors.abbrevation && errors.abbrevation.type === 'maxLength' && (
          <Toast
            message={errors.abbrevation.message}
            close={false}
            onCloseAction={() => {}}
            additionalClass='error-toast'
          />
        )}
        <div className='controls'>
          <button
            role='save-details-button'
            className='primary-button'
            onClick={handleSubmit(handleEditBasicInfo)}
            disabled={disabled}
          >
            {t('save')}
          </button>
        </div>
      </form>
    </div>
  );
}
