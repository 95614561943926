import {AwsConfig} from '../../common/types/aws';
import {ConfigActionTypes} from './types';

export function getConfig() {
  return {
    type: ConfigActionTypes.GET,
  };
}

export function setConfig(config: AwsConfig) {
  return {
    type: ConfigActionTypes.SET,
    payload: config,
  };
}
