import {QRCodeSVG} from 'qrcode.react';
import {useTranslation} from 'react-i18next';
import {Branding} from '../../../modules/privateDistribution/types';

import './index.scss';

interface IProps {
  branding?: Branding;
}

export function DownloadOnDesktop({branding}: IProps): JSX.Element {
  const {t} = useTranslation();

  const background = branding?.BACKGROUND
    ? branding?.BACKGROUND?.url
      ? `url(${branding?.BACKGROUND?.url}) center center / cover`
      : branding?.BACKGROUND?.color
      ? branding?.BACKGROUND?.color
      : 'none'
    : 'none';

  return (
    <div
      className='download-mobile-page'
      style={{
        background,
      }}
    >
      <div className='desktop wrapper'>
        <div className='logo' style={{background: branding?.LOGO?.color}}>
          <img src={branding?.LOGO?.url} className='logo-image' />
        </div>
        <div className='content'>
          <div className='header'>
            <img src={branding?.ICON?.url} className='icon' />
            <div className='title'>
              <strong>{t('mobile-download-title')}</strong>
              <span>{t('mobile-download-subtitle')}</span>
            </div>
          </div>
          <div className='qr-code'>
            <QRCodeSVG
              value={window.location.href}
              style={{
                width: '35%',
                height: 'auto',
                margin: '32px auto',
                display: 'block',
              }}
            />
          </div>
          <small>{t('mobile-download-content')}</small>
        </div>
      </div>
    </div>
  );
}
