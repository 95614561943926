// import {useSelector} from 'react-redux';
// import {Navigate, Outlet} from 'react-router';
// import {selectUser} from '../../../modules/auth/selectors';
// import useAmplifyAuth from '../../hooks/auth';

// interface IProps {
//   fetchUser: boolean;
// }

// export function ProtectedRoute({fetchUser = true}: IProps) {
//   useAmplifyAuth();

//   const user = fetchUser ? useSelector(selectUser) : undefined;

//   if (user === null) {
//     return <Navigate replace to='/login' />;
//   }

//   return <Outlet />;
// }
import {useSelector} from 'react-redux';
import {Navigate, Outlet} from 'react-router';
import {selectUser} from '../../../modules/auth/selectors';
import useAmplifyAuth from '../../hooks/auth';

export function ProtectedRoute() {
  useAmplifyAuth();

  const user = useSelector(selectUser);

  if (user === null) {
    return <Navigate replace to='/login' />;
  }

  return <Outlet />;
}
