import {useTranslation} from 'react-i18next';

export function Ios(): JSX.Element {
  const {t} = useTranslation();

  return (
    <>
      <hr />
      <h3>{t('instalation-instructions')}</h3>
      <div className='content-section'>
        <p>
          1. Click on the blue <b>Download</b> button above.
        </p>
        <p>
          2. Tap into your iPhone <b>Settings</b>.
        </p>
        <p>
          3. Go to <b>General</b> and select <b>VPN & Device Management</b>.
        </p>
        <p>
          4. Under <b>Enterprise App</b>, select <b>Sportradar AG</b>.
        </p>
        <p>
          5. Please then select <b>Trust "Sportradar AG"</b>.
        </p>
        <p>6. Return to the app.</p>
      </div>
    </>
  );
}
