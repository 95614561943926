import {ReduxState} from '../../common/types/state';

export function selectDistribution(state: ReduxState) {
  return state.distribution.distribution;
}

export function selectUploadedImage(state: ReduxState) {
  return state.distribution.uploadedImage;
}

export function selectIsLoading(state: ReduxState) {
  return state.distribution.loading;
}

export function selectUser(state: ReduxState) {
  return state.distribution.user;
}

export function selectPin(state: ReduxState) {
  return state.distribution.pin;
}

export function selectIsDownloading(state: ReduxState) {
  return state.distribution.downloading;
}

export function selectIfImageWasUploaded(state: ReduxState) {
  return state.distribution.wasImageUploaded;
}

export function selectDownloadProgress(state: ReduxState) {
  return state.distribution.progress;
}
