import {Route} from '../common/interfaces/routes';
import {Client} from '../pages/client';
import {ClientList} from '../pages/clients';
import {Download} from '../pages/download';
import {DownloadMobileApp} from '../pages/downloadMobile';
import {Login} from '../pages/login';

export const routes: Route[] = [
  {
    path: '/login',
    key: 'login',
    name: 'Login Page',
    component: Login,
    exact: true,
  },
  {
    path: '/logout',
    key: 'logout',
    name: 'Login Page',
    component: Login,
    exact: true,
  },
  {
    path: '/app/download/:key',
    key: 'download',
    name: 'Download',
    component: Download,
    exact: true,
  },
  {
    path: '/mobile/download/:hash',
    key: 'mobile',
    name: 'Download mobile app',
    component: DownloadMobileApp,
    exact: true,
  },
];

export const protectedRoutes: Route[] = [
  {
    path: '/client/:id',
    key: 'client',
    name: 'Client Page',
    component: Client,
    exact: true,
  },
  {
    path: '/',
    key: 'home',
    name: 'Home',
    component: ClientList,
    exact: true,
  },
];
