import {useState, useEffect} from 'react';
import {Auth, Hub} from 'aws-amplify';
import {HubCapsule, HubPayload} from '@aws-amplify/core';
import {useDispatch} from 'react-redux';
import {
  fetchUserFailure,
  fetchUserInit,
  fetchUserSuccess,
  resetUser,
} from '../../../modules/auth/actions';

export function useAmplifyAuth() {
  const [triggerFetch, setTriggerFetch] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    const fetchUserData = async () => {
      if (isMounted) {
        dispatch(fetchUserInit());
        try {
          const data = await Auth.currentAuthenticatedUser();
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (data) {
            dispatch(fetchUserSuccess(data));
          }
        } catch (error) {
          dispatch(fetchUserFailure());
        }
      }
    };

    const HubListener = () => {
      Hub.listen('auth', (data: HubCapsule) => {
        const {payload} = data;
        onAuthEvent(payload);
      });
    };

    const onAuthEvent = (payload: HubPayload) => {
      switch (payload.event) {
        case 'signIn':
          if (isMounted) {
            setTriggerFetch(true);
          }
          break;
        default:
          return;
      }
    };

    HubListener();
    fetchUserData().catch(error => {
      console.log('Error fetching user data ', error);
    });

    return () => {
      Hub.remove('auth', () => {
        //do nothing
      });
      isMounted = false;
    };
  }, [dispatch, triggerFetch]);

  const handleSignout = async () => {
    try {
      await Auth.signOut();
      setTriggerFetch(false);
      dispatch(resetUser());
    } catch (error) {
      console.error('Error signing out user ', error);
    }
  };

  return {handleSignout};
}

export default useAmplifyAuth;
