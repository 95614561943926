import {CognitoUser} from '../../common/types/cognitoUser';

export const FETCH_USER_INIT = '@auth/FETCH_USER_INIT';
export const FETCH_USER_SUCCESS = '@auth/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = '@auth/FETCH_USER_FAILURE';
export const RESET_USER = '@auth/RESET_USER';

export interface AuthState {
  loading: boolean;
  user?: CognitoUser | null;
  error: boolean;
}
