import {Language} from '../../common/types/api';

export enum LanguagesActionTypes {
  GET = '@languages/GET',
  SET = '@languages/SET',
}

export interface LanguagesState {
  languages: Language[];
}
